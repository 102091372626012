import React from 'react';
// import { Router } from '@reach/router';
// import { onRouteUpdate } from 'gatsby';
import { Location } from '@reach/router';
import { NavProvider } from './src/context/NavProvider';
import { ConfiguratorProvider } from './src/context/ConfiguratorProvider';

// FOUC
// in use
// export function onClientEntry() {
//   window.addEventListener('load', () => {
//     document.body.className = document.body.className.replace(/\bno-js\b/, '');
//   });
// }
// not in use
// exports.onClientEntry = () => {
//   window.addEventListener('load', () => {
//     document.body.className = document.body.className.replace(/\bno-js\b/, '');
//   });
// };

// for tawk.to widget
const excludedPaths = [
  '/wedding-packages/',
  '/wedding/elopement/',
  '/storage-packages/',
  '/packages/checkout/',
];

// add retries to help ensure the tawk.to widget is hidden
// on page refresh
const handleTawkToWidget = (pathname, retries = 10) => {
  // console.log('handleTawkToWidget called with pathname:', pathname);
  if (typeof document !== 'undefined') {
    if (excludedPaths.includes(pathname)) {
      // console.log('Hiding Tawk.to widget');
      const tawkIframes = document.querySelectorAll(
        'iframe[src*="tawk.to"], iframe[title="chat widget"]'
      );
      const tawkDivs = document.querySelectorAll(
        'div[id^="z"], div[id^="b"], div[id^="w"], div[id^="chat-bubble"]'
      );

      if (tawkIframes.length > 0 || tawkDivs.length > 0) {
        tawkIframes.forEach((iframe) => {
          iframe.style.display = 'none';
        });
        tawkDivs.forEach((div) => {
          div.style.display = 'none';
        });
      } else if (retries > 0) {
        // console.log('Retrying to hide Tawk.to widget, retries left:', retries);
        setTimeout(() => handleTawkToWidget(pathname, retries - 1), 300);
      }
    } else {
      // console.log('Showing Tawk.to widget');
      const tawkIframes = document.querySelectorAll(
        'iframe[src*="tawk.to"], iframe[title="chat widget"]'
      );
      tawkIframes.forEach((iframe) => {
        iframe.style.display = 'block';
        iframe.style.position = 'absolute';
      });

      const tawkDivs = document.querySelectorAll(
        'div[id^="z"], div[id^="b"], div[id^="w"], div[id^="chat-bubble"]'
      );
      tawkDivs.forEach((div) => {
        div.style.display = 'block';
      });
    }
  }
};

// fix scrolling issue and tawk.to widget
export const onRouteUpdate = ({ location }) => {
  const pathname = location.pathname;

  // handle tawk.to widget
  handleTawkToWidget(pathname);

  // fix scrolling issues
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      left: 0,
    });
  };

  const scrollToHash = () => {
    const hash = location.hash;
    if (hash) {
      const element = document.querySelector(hash);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  };

  // Ensure the scroll action happens after the route update and page content is rendered
  setTimeout(() => {
    requestAnimationFrame(() => {
      if (location.hash) {
        scrollToHash();
      } else {
        scrollToTop();
      }
    });
  }, 0); // add delay to ensure content is rendered if needed
};

// tawk.to widget is hidden on specific pages
export const onInitialClientRender = () => {
  // console.log('onInitialClientRender called');
  setTimeout(() => {
    handleTawkToWidget(window.location.pathname);
  }, 375);
};

export const wrapRootElement = ({ element }) => (
  <Location>
    {({ location }) => (
      <ConfiguratorProvider location={location}>
        <NavProvider>{element}</NavProvider>
      </ConfiguratorProvider>
    )}
  </Location>
);

// export function wrapRootElement() { return <Provider />; }
// exports.wrapRootElement = () => <Provider />
// export const wrapRootElement = Provider;
// export default wrapRootElement = Provider;
