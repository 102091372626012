import React, { createContext, useState, useEffect, useRef } from 'react';
import { useStaticQuery, graphql, navigate } from 'gatsby';
import { useLocalStorage } from '../components/hooks/useLocalStorage';

import { usePackageConfiguratorWeddingsState } from '../components/hooks/packageConfiguratorWeddingsState';
import {
  // handleOnePhotographerSelected,
  handleOnePhotographerData,
  handleSecondPhotographerSelected,
  handleSecondPhotographerData,
  handleWeddingAlbumSelected,
  handleWeddingAlbumProfessionalDesignSelected,
  handleWeddingAlbumAmount,
  handleWeddingAlbumProfessionalDesignAmount,
  handlePhotoRetouching,
  handlePhotoRetouchingSelected,
  handleEngagementPhotoshoot,
  handleEngagementPhotoshootSelected,
  handleExpeditedPhotoProcessing,
  handlePremiumPhotoPackageSelected,
  handleOneVideographerData,
  handleSecondVideographerSelected,
  handleSecondVideographerData,
  handleCinematographySelected,
  handleTrailerOnlySelected,
  handleExtendedTrailerSelected,
  handleTraditionalSelected,
  handlePremiumVideoPackageSelected,
  handleSocialMediaPackagePhotoSelected,
  handleSocialMediaPackageVideoSelected,
  handleSocialMediaPackagePhotoAndVideoSelected,
  handlePhotoBoothSelected,
} from '../components/hooks/packageConfiguratorWeddingsMethods';
import { usePackageConfiguratorElopementsState } from '../components/hooks/packageConfiguratorElopementsState';
import {
  handleOnePhotographerDataElopements,
  handleWeddingAlbumSelectedElopements,
  handleWeddingAlbumProfessionalDesignSelectedElopements,
  handleWeddingAlbumAmountElopements,
  handleWeddingAlbumProfessionalDesignAmountElopements,
  handlePremiumPhotoPackageSelectedElopements,
  handlePremiumVideoPackageSelectedElopements,
  handleOneVideographerDataElopements,
  handleSocialMediaPackagePhotoSelectedElopements,
  handleSocialMediaPackageVideoSelectedElopements,
  handleSocialMediaPackagePhotoAndVideoSelectedElopements,
  handleOfficiantSelectedElopements,
} from '../components/hooks/packageConfiguratorElopementsMethods';

export const ConfiguratorContext = createContext();

export const ConfiguratorProvider = ({ children, location }) => {
  // console.log('CONFIGURATOR PROVIDER ACTIVE', location);

  // Check if window is defined to ensure SSR compatibility
  const isBrowser = typeof window !== 'undefined';
  if (!isBrowser) return null;

  // Avoids running useEffect on first render
  const isFirstRender = useRef(true);

  const data = useStaticQuery(graphql`
    query packagesQuery {
      weddings: wpPage(template: { templateName: { eq: "WeddingPackages" } }) {
        weddingPackagesACF {
          weddingsConfigurator {
            engagementPhotoShoot {
              oneHour {
                price
              }
              twoHours {
                price
              }
            }
            photoBooth {
              allInclusivePrice
              allInclusivePriceWithOnePhotographer
              allInclusivePriceWithOnePhotographerAndOneVideographer
              basicPackagePrice
              basicPackagePriceWithOnePhotographer
              basicPackagePriceWithOnePhotographerAndOneVideographer
              photoBoothInfo {
                text
                slideImage1 {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          avifOptions: { quality: 90 }
                          formats: AVIF
                          width: 640
                          height: 498
                          placeholder: NONE
                          layout: CONSTRAINED
                          breakpoints: [640]
                          sizes: "(min-width: 640px) 640px, 100vw"
                        )
                      }
                    }
                  }
                }
                slideImage2 {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          avifOptions: { quality: 90 }
                          formats: AVIF
                          width: 640
                          height: 498
                          placeholder: NONE
                          layout: CONSTRAINED
                          breakpoints: [640]
                          sizes: "(min-width: 640px) 640px, 100vw"
                        )
                      }
                    }
                  }
                }
                slideImage3 {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          avifOptions: { quality: 90 }
                          formats: AVIF
                          width: 640
                          height: 498
                          placeholder: NONE
                          layout: CONSTRAINED
                          breakpoints: [640]
                          sizes: "(min-width: 640px) 640px, 100vw"
                        )
                      }
                    }
                  }
                }
                slideImage4 {
                  node {
                    localFile {
                      childImageSharp {
                        gatsbyImageData(
                          avifOptions: { quality: 90 }
                          formats: AVIF
                          width: 640
                          height: 498
                          placeholder: NONE
                          layout: CONSTRAINED
                          breakpoints: [640]
                          sizes: "(min-width: 640px) 640px, 100vw"
                        )
                      }
                    }
                  }
                }
              }
            }
            photography {
              onePhotographer {
                averageColorCorrectedImagesFor8
                colorCorrectedImagesAmount8AndAbove
                colorCorrectedImagesAmountBelow8
                priceFor8
                pricePerHour8AndAbove
                pricePerHourBelow8
              }
              secondPhotographer {
                averageColorCorrectedImagesFor8
                colorCorrectedImagesAmount8AndAbove
                colorCorrectedImagesAmountBelow8
                priceFor8
                pricePerHour8AndAbove
                pricePerHourBelow8
              }
              weddingAlbum {
                by10 {
                  numberOfPages
                  price
                  professionalAlbumDesignPrice
                  shippingFee
                }
                by12 {
                  numberOfPages
                  price
                  professionalAlbumDesignPrice
                  shippingFee
                }
                weddingAlbumInfo {
                  text
                  slideImage1 {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            avifOptions: { quality: 90 }
                            formats: AVIF
                            width: 800
                            height: 550
                            placeholder: NONE
                            layout: CONSTRAINED
                            breakpoints: [800]
                            sizes: "(min-width: 800px) 800px, 100vw"
                          )
                        }
                      }
                    }
                  }
                  slideImage2 {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            avifOptions: { quality: 90 }
                            formats: AVIF
                            width: 800
                            height: 550
                            placeholder: NONE
                            layout: CONSTRAINED
                            breakpoints: [800]
                            sizes: "(min-width: 800px) 800px, 100vw"
                          )
                        }
                      }
                    }
                  }
                  slideImage3 {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            avifOptions: { quality: 90 }
                            formats: AVIF
                            width: 800
                            height: 550
                            placeholder: NONE
                            layout: CONSTRAINED
                            breakpoints: [800]
                            sizes: "(min-width: 800px) 800px, 100vw"
                          )
                        }
                      }
                    }
                  }
                }
                professionalAlbumDesignInfo {
                  text
                }
              }
              photoRetouching {
                retouchedPhotos10Price
                retouchedPhotos20Price
                retouchedPhotos30Price
              }
              photoRetouchingInfo {
                text
                beforeAfter {
                  example1 {
                    afterImage {
                      node {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              avifOptions: { quality: 90 }
                              formats: AVIF
                              width: 800
                              height: 550
                              placeholder: NONE
                              layout: CONSTRAINED
                              breakpoints: [800]
                              sizes: "(min-width: 800px) 800px, 100vw"
                            )
                          }
                        }
                      }
                    }
                    beforeImage {
                      node {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              avifOptions: { quality: 90 }
                              formats: AVIF
                              width: 800
                              height: 550
                              placeholder: NONE
                              layout: CONSTRAINED
                              breakpoints: [800]
                              sizes: "(min-width: 800px) 800px, 100vw"
                            )
                          }
                        }
                      }
                    }
                  }
                  example2 {
                    afterImage {
                      node {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              avifOptions: { quality: 90 }
                              formats: AVIF
                              width: 800
                              height: 550
                              placeholder: NONE
                              layout: CONSTRAINED
                              breakpoints: [800]
                              sizes: "(min-width: 800px) 800px, 100vw"
                            )
                          }
                        }
                      }
                    }
                    beforeImage {
                      node {
                        localFile {
                          childImageSharp {
                            gatsbyImageData(
                              avifOptions: { quality: 90 }
                              formats: AVIF
                              width: 800
                              height: 550
                              placeholder: NONE
                              layout: CONSTRAINED
                              breakpoints: [800]
                              sizes: "(min-width: 800px) 800px, 100vw"
                            )
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
            videography {
              oneVideographer {
                priceFor8
                priceFor8WithOnePhotographer
                pricePerHour8AndAbove
                pricePerHourBelow8
              }
              secondVideographer {
                priceFor8
                pricePerHour8AndAbove
                pricePerHourBelow8
              }
              videoEditingOptions {
                cinematographyPrice
                cinematographyPriceWithSecondVideographer
                traditionalPrice
                traditionalPriceWithSecondVideographer
                trailerOnlyPrice
                trailerOnlyPriceWithSecondVideographer
                extendedTrailerPrice
                extendedTrailerPriceWithSecondVideographer
              }
              videoEditingOptionsInfo {
                cinematography {
                  text
                  slideVideo1
                  slideVideo2
                  slideVideo3
                }
                trailerOnly {
                  text
                  slideVideo1
                  slideVideo2
                  slideVideo3
                }
                extendedTrailer {
                  text
                  slideVideo1
                  slideVideo2
                  slideVideo3
                }
                traditional {
                  text
                  slideVideo1
                  slideVideo2
                  slideVideo3
                }
              }
            }
            socialMediaPackage {
              photo {
                price
                description
                reel1
                reel2
                reel3
                reel4
                reel5
                reel6
                reel7
                reel8
              }
              video {
                price
                description
                reel1
                reel2
                reel3
                reel4
                reel5
                reel6
                reel7
                reel8
              }
              photoAndVideo {
                price
                description
                reel1
                reel2
                reel3
                reel4
                reel5
                reel6
                reel7
                reel8
              }
            }
            expeditedPhotoProcessing {
              price
              expeditedPhotoProcessingInfo {
                text
              }
            }
            storage {
              info
              photo {
                info
                options {
                  option1PopupText
                  option1SidebarText
                  option1Price
                  option2PopupText
                  option2SidebarText
                  option2Price
                  option3PopupText
                  option3SidebarText
                  option3Price
                }
              }
              video {
                info
                options {
                  option1PopupText
                  option1SidebarText
                  option1Price
                  option2PopupText
                  option2SidebarText
                  option2Price
                  option3PopupText
                  option3SidebarText
                  option3Price
                }
              }
              photoVideo {
                info
                options {
                  option1PopupText
                  option1SidebarText
                  option1Price
                  option2PopupText
                  option2SidebarText
                  option2Price
                  option3PopupText
                  option3SidebarText
                  option3Price
                }
              }
            }
            premiumPhotoPackage {
              price
              discount
              description
              popupTitle
              popupTitleDiscount
              popupBody
            }
            premiumVideoPackage {
              price
              discount
              description
              popupTitle
              popupTitleDiscount
              popupBody
            }
          }
        }
      }
      elopements: wpPage(template: { templateName: { eq: "Elopement" } }) {
        elopementPackagesACF {
          elopementsConfigurator {
            photography {
              onePhotographer {
                priceFor1
                pricePerHour
              }
              weddingAlbum {
                by10 {
                  numberOfPages
                  price
                  professionalAlbumDesignPrice
                  shippingFee
                }
                by12 {
                  numberOfPages
                  price
                  professionalAlbumDesignPrice
                  shippingFee
                }
                weddingAlbumInfo {
                  text
                  slideImage1 {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            avifOptions: { quality: 90 }
                            formats: AVIF
                            width: 800
                            height: 550
                            placeholder: NONE
                            layout: CONSTRAINED
                            breakpoints: [800]
                            sizes: "(min-width: 800px) 800px, 100vw"
                          )
                        }
                      }
                    }
                  }
                  slideImage2 {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            avifOptions: { quality: 90 }
                            formats: AVIF
                            width: 800
                            height: 550
                            placeholder: NONE
                            layout: CONSTRAINED
                            breakpoints: [800]
                            sizes: "(min-width: 800px) 800px, 100vw"
                          )
                        }
                      }
                    }
                  }
                  slideImage3 {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            avifOptions: { quality: 90 }
                            formats: AVIF
                            width: 800
                            height: 550
                            placeholder: NONE
                            layout: CONSTRAINED
                            breakpoints: [800]
                            sizes: "(min-width: 800px) 800px, 100vw"
                          )
                        }
                      }
                    }
                  }
                  slideImage4 {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            avifOptions: { quality: 90 }
                            formats: AVIF
                            width: 800
                            height: 550
                            placeholder: NONE
                            layout: CONSTRAINED
                            breakpoints: [800]
                            sizes: "(min-width: 800px) 800px, 100vw"
                          )
                        }
                      }
                    }
                  }
                  slideImage5 {
                    node {
                      localFile {
                        childImageSharp {
                          gatsbyImageData(
                            avifOptions: { quality: 90 }
                            formats: AVIF
                            width: 800
                            height: 550
                            placeholder: NONE
                            layout: CONSTRAINED
                            breakpoints: [800]
                            sizes: "(min-width: 800px) 800px, 100vw"
                          )
                        }
                      }
                    }
                  }
                }
                professionalAlbumDesignInfo {
                  text
                }
              }
            }
            videography {
              oneVideographer {
                priceFor1
                pricePerHour
              }
            }
            socialMediaPackage {
              photo {
                price
                description
                reel1
                reel2
                reel3
                reel4
                reel5
                reel6
                reel7
                reel8
              }
              video {
                price
                description
                reel1
                reel2
                reel3
                reel4
                reel5
                reel6
                reel7
                reel8
              }
              photoAndVideo {
                price
                description
                reel1
                reel2
                reel3
                reel4
                reel5
                reel6
                reel7
                reel8
              }
            }
            officiant {
              price
            }
            storage {
              info
              photo {
                info
                options {
                  option1PopupText
                  option1SidebarText
                  option1Price
                  option2PopupText
                  option2SidebarText
                  option2Price
                  option3PopupText
                  option3SidebarText
                  option3Price
                }
              }
              video {
                info
                options {
                  option1PopupText
                  option1SidebarText
                  option1Price
                  option2PopupText
                  option2SidebarText
                  option2Price
                  option3PopupText
                  option3SidebarText
                  option3Price
                }
              }
              photoVideo {
                info
                options {
                  option1PopupText
                  option1SidebarText
                  option1Price
                  option2PopupText
                  option2SidebarText
                  option2Price
                  option3PopupText
                  option3SidebarText
                  option3Price
                }
              }
            }
            premiumPhotoPackage {
              price
              discount
              description
              popupTitle
              popupTitleDiscount
              popupBody
            }
            premiumVideoPackage {
              price
              discount
              description
              popupTitle
              popupTitleDiscount
              popupBody
            }
          }
        }
      }
      storage: wpPage(template: { templateName: { eq: "StoragePackages" } }) {
        storagePackagesACF {
          photoDownloadLinkPrice
          videoDownloadLinkPrice
          photoVideoDownloadLinkPrice
        }
      }
    }
  `);

  // dev only
  // const [isDev] = useState(process.env.IS_DEV || false);
  const [isDev] = useState(false || false);

  // local storage
  const { getLocalStorageValue } = useLocalStorage();

  // TODO: storage photo, video, photoVideo
  //#region Handle key events
  // TODO: accessability
  const [keyPressed, setKeyPressed] = useState(false);

  const handleKeyPressed = (key) => {
    if (key === 13 && !keyPressed) {
      setKeyPressed(true);
    }
  };

  const handleKeyUp = () => {
    setKeyPressed(false);
  };

  const handlePreventDefault = (ev) => {
    if (ev.keyCode === 13) {
      ev.preventDefault();
    }
  };
  //#endregion

  //#region Packages data
  // set default value for local storage packageConfiguratorWeddings,
  // packageConfiguratorElopements, and packageConfiguratorStorage,
  // and return the value from local storage
  const [packageConfiguratorWeddings, setPackageConfiguratorWeddings] =
    useState(() => {
      const storedValue = getLocalStorageValue('packageConfiguratorWeddings');
      return storedValue !== null ? storedValue : false;
    });

  const [packageConfiguratorElopements, setPackageConfiguratorElopements] =
    useState(() => {
      const storedValue = getLocalStorageValue('packageConfiguratorElopements');
      return storedValue !== null ? storedValue : false;
    });

  const [packageConfiguratorStorage, setPackageConfiguratorStorage] = useState(
    () => {
      const storedValue = getLocalStorageValue('packageConfiguratorStorage');
      return storedValue !== null ? storedValue : false;
    }
  );
  //#endregion

  //#region Main values
  // grand total
  const [grandTotalPrice, setGrandTotalPrice] = useState(0);
  const [grandTotalPriceElopements, setGrandTotalPriceElopements] = useState(0);
  const [grandTotalPriceStorage, setGrandTotalPriceStorage] = useState(0);

  // graphql data
  const configuratorWeddings =
    data.weddings && data.weddings.weddingPackagesACF
      ? data.weddings.weddingPackagesACF.weddingsConfigurator
      : null;
  const configuratorElopements =
    data.elopements && data.elopements.elopementPackagesACF
      ? data.elopements.elopementPackagesACF.elopementsConfigurator
      : null;
  const configuratorStorage =
    data.storage && data.storage.storagePackagesACF
      ? data.storage.storagePackagesACF
      : null;
  // values for modals in child component PackagesConfiguratorWeddings
  const weddingAlbumInfoModal =
    configuratorWeddings.photography.weddingAlbum.weddingAlbumInfo;
  const professionalAlbumDesignInfoModal =
    configuratorWeddings.photography.weddingAlbum.professionalAlbumDesignInfo;

  // values for modals in child component PackagesConfiguratorElopements
  const weddingAlbumInfoModalElopements =
    configuratorElopements.photography.weddingAlbum.weddingAlbumInfo;
  const professionalAlbumDesignInfoModalElopements =
    configuratorElopements.photography.weddingAlbum.professionalAlbumDesignInfo;

  // weddings data
  const onePhotographer = configuratorWeddings.photography.onePhotographer;
  const secondPhotographer =
    configuratorWeddings.photography.secondPhotographer;

  const weddingAlbum10 = configuratorWeddings.photography.weddingAlbum.by10;
  const weddingAlbum12 = configuratorWeddings.photography.weddingAlbum.by12;

  const retouchedPhotos10 =
    configuratorWeddings.photography.photoRetouching.retouchedPhotos10Price;
  const retouchedPhotos20 =
    configuratorWeddings.photography.photoRetouching.retouchedPhotos20Price;
  const retouchedPhotos30 =
    configuratorWeddings.photography.photoRetouching.retouchedPhotos30Price;

  const photoRetouchingInfo =
    configuratorWeddings.photography.photoRetouchingInfo;

  const oneVideographer = configuratorWeddings.videography.oneVideographer;
  const secondVideographer =
    configuratorWeddings.videography.secondVideographer;

  const videoEditingOptions =
    configuratorWeddings.videography.videoEditingOptions;
  const cinematographyInfo =
    configuratorWeddings.videography.videoEditingOptionsInfo.cinematography;
  const trailerOnlyInfo =
    configuratorWeddings.videography.videoEditingOptionsInfo.trailerOnly;
  const extendedTrailerInfo =
    configuratorWeddings.videography.videoEditingOptionsInfo.extendedTrailer;
  const traditionalInfo =
    configuratorWeddings.videography.videoEditingOptionsInfo.traditional;

  const engagementOneHour = configuratorWeddings.engagementPhotoShoot.oneHour;
  const engagementTwoHours = configuratorWeddings.engagementPhotoShoot.twoHours;

  const photoBooth = configuratorWeddings.photoBooth;
  const photoBoothInfo = configuratorWeddings.photoBooth.photoBoothInfo;

  const socialMediaPackage = configuratorWeddings.socialMediaPackage;

  const expeditedPhotoProcessing =
    configuratorWeddings.expeditedPhotoProcessing;
  const expeditedPhotoProcessingInfo =
    configuratorWeddings.expeditedPhotoProcessing.expeditedPhotoProcessingInfo;

  const storage = configuratorWeddings.storage;

  const premiumPhotoPackage = configuratorWeddings.premiumPhotoPackage;
  const premiumVideoPackage = configuratorWeddings.premiumVideoPackage;
  //#endregion

  //#region Configurator Weddings State
  // pack graphql data into an object
  const graphqlData = {
    onePhotographer: onePhotographer,
    secondPhotographer: secondPhotographer,
    weddingAlbum10: weddingAlbum10,
    weddingAlbum12: weddingAlbum12,
    retouchedPhotos10: retouchedPhotos10,
    retouchedPhotos20: retouchedPhotos20,
    retouchedPhotos30: retouchedPhotos30,
    photoRetouchingInfo: photoRetouchingInfo,
    engagementOneHour: engagementOneHour,
    engagementTwoHours: engagementTwoHours,
    expeditedPhotoProcessing: expeditedPhotoProcessing,
    premiumPhotoPackage: premiumPhotoPackage,
    oneVideographer: oneVideographer,
    secondVideographer: secondVideographer,
    videoEditingOptions: videoEditingOptions,
    premiumVideoPackage: premiumVideoPackage,
    socialMediaPackage: socialMediaPackage,
    photoBooth: photoBooth,
  };
  // Configurator Weddings State
  const packageConfiguratorWeddingsState =
    usePackageConfiguratorWeddingsState(graphqlData);

  const {
    // Photography
    // One Photographer
    onePhotographerSelected,
    setOnePhotographerSelected,
    onePhotographerControl,
    onePhotographerHour,
    setOnePhotographerHour,
    onePhotographerCCImages,
    setOnePhotographerCCImages,
    onePhotographerPriceDefault,
    onePhotographerPrice,
    setOnePhotographerPrice,
    onePhotographerPriceForBelow8,
    onePhotographerPriceForAbove8,
    onePhotographerCCImages8,
    onePhotographerCCImagesAbove8,
    onePhotographerCCImagesBelow8,
    // Second Photographer
    secondPhotographerSelected,
    secondPhotographerControl,
    secondPhotographerHour,
    setSecondPhotographerHour,
    secondPhotographerPriceDefault,
    secondPhotographerPrice,
    setSecondPhotographerPrice,
    secondPhotographerCCImages,
    setSecondPhotographerCCImages,
    secondPhotographerPriceForAbove8,
    secondPhotographerPriceForBelow8,
    secondPhotographerCCImages8,
    secondPhotographerCCImagesAbove8,
    secondPhotographerCCImagesBelow8,
    // Engagement photoshoot
    engagementPhotoShootSelected,
    setEngagementPhotoShootSelected,
    setEngagementPhotoShootPackageInfo,
    engagementPhotoShootPrice,
    setEngagementPhotoShootPrice,
    engagementOneHourSelected,
    engagementTwoHoursSelected,
    engagementOneHourPrice,
    engagementTwoHoursPrice,
    // Expedited photo processing
    expeditedPhotoProcessingPrice,
    // Wedding albums
    uriChange,
    // 10x10
    weddingAlbum10Price,
    setWeddingAlbum10Price,
    weddingAlbum10PriceTotal,
    setWeddingAlbum10PriceTotal,
    weddingAlbum10Selected,
    weddingAlbum10Amount,
    weddingAlbum10AmountFree,
    setWeddingAlbum10AmountFree,
    weddingAlbum10ProfessionalDesignAmount,
    setWeddingAlbum10ProfessionalDesignAmount,
    weddingAlbum10ProfessionalDesignSelected,
    setWeddingAlbum10ProfessionalDesignSelected,
    weddingAlbum10Control,
    setWeddingAlbum10Info,
    setWeddingAlbum10AmountFreeInfo,
    setWeddingAlbum10ProfessionalDesignInfo,
    weddingAlbum10PriceDefault,
    weddingAlbum10ShippingFee,
    weddingAlbum10ShippingFeeTotal,
    setWeddingAlbum10ShippingFeeTotal,
    weddingAlbum10ProfessionalDesignPrice,
    weddingAlbum10ProfessionalDesignPriceTotal,
    setWeddingAlbum10ProfessionalDesignPriceTotal,
    // 12x12
    weddingAlbum12Price,
    setWeddingAlbum12Price,
    weddingAlbum12PriceTotal,
    setWeddingAlbum12PriceTotal,
    weddingAlbum12Selected,
    weddingAlbum12Amount,
    weddingAlbum12AmountFree,
    setWeddingAlbum12AmountFree,
    weddingAlbum12ProfessionalDesignAmount,
    setWeddingAlbum12ProfessionalDesignAmount,
    weddingAlbum12ProfessionalDesignSelected,
    setWeddingAlbum12ProfessionalDesignSelected,
    weddingAlbum12Control,
    setWeddingAlbum12Info,
    setWeddingAlbum12AmountFreeInfo,
    setWeddingAlbum12ProfessionalDesignInfo,
    weddingAlbum12PriceDefault,
    weddingAlbum12ShippingFee,
    weddingAlbum12ShippingFeeTotal,
    setWeddingAlbum12ShippingFeeTotal,
    weddingAlbum12ProfessionalDesignPrice,
    weddingAlbum12ProfessionalDesignPriceTotal,
    setWeddingAlbum12ProfessionalDesignPriceTotal,
    // Photo Retouching
    photoRetouchingSelected,
    setPhotoRetouchingSelected,
    retouchedPhotos10Price,
    photoRetouching10Selected,
    retouchedPhotos20Price,
    photoRetouching20Selected,
    retouchedPhotos30Price,
    photoRetouching30Selected,
    setPhotoRetouchingPackageInfo,
    photoRetouchingPackagePrice,
    setPhotoRetouchingPackagePrice,
    // Expedited photo processing
    expeditedPhotoProcessingSelected,
    // Premium package (Photo)
    premiumPhotoPackageSelected,
    setPremiumPhotoPackageSelected,
    // Videography
    // One Videographer
    oneVideographerSelected,
    setOneVideographerSelected,
    oneVideographerControl,
    setOneVideographerControl,
    oneVideographerHour,
    setOneVideographerHour,
    oneVideographerPriceDefault,
    oneVideographerPrice,
    setOneVideographerPrice,
    oneVideographerPriceForAbove8,
    oneVideographerPriceForBelow8,
    oneVideographerPriceWithOnePhotographer,
    // Second Videographer
    secondVideographerSelected,
    secondVideographerControl,
    secondVideographerHour,
    setSecondVideographerHour,
    secondVideographerPriceDefault,
    secondVideographerPrice,
    setSecondVideographerPrice,
    secondVideographerPriceForAbove8,
    secondVideographerPriceForBelow8,
    // Video editing options
    // Cinematography
    cinematographySelected,
    cinematographyPrice,
    setCinematographyPrice,
    cinematographyPriceWithOneVideographer,
    cinematographyPriceWithSecondVideographer,
    // Trailer only
    trailerOnlySelected,
    trailerOnlyPrice,
    setTrailerOnlyPrice,
    trailerOnlyPriceWithOneVideographer,
    trailerOnlyPriceWithSecondVideographer,
    // Extended trailer
    extendedTrailerSelected,
    extendedTrailerPrice,
    setExtendedTrailerPrice,
    extendedTrailerPriceWithOneVideographer,
    extendedTrailerPriceWithSecondVideographer,
    // Traditional
    traditionalSelected,
    traditionalPrice,
    setTraditionalPrice,
    traditionalPriceWithOneVideographer,
    traditionalPriceWithSecondVideographer,
    // Premium package (Video)
    premiumVideoPackageSelected,
    setPremiumVideoPackageSelected,
    setPremiumVideoPackageDescription,
    setPremiumVideoPackagePrice,
    premiumVideoPackagePriceDefault,
    premiumVideoPackagePriceDiscount,
    premiumVideoPackageDescriptionDefault,
    setPremiumVideoPackagePopupTitle,
    premiumVideoPackagePopupTitleDefault,
    premiumVideoPackagePopupTitleDiscount,
    // Social media package (Sneak Peek)
    // Photo
    socialMediaPackagePhotoSelected,
    socialMediaPackagePhotoPrice,
    // Video
    socialMediaPackageVideoSelected,
    socialMediaPackageVideoPrice,
    // Photo & Video
    socialMediaPackagePhotoAndVideoSelected,
    socialMediaPackagePhotoAndVideoPrice,
    // Photo booth
    photoBoothPackagePrice,
    setPhotoBoothPackagePrice,
    // Basic package
    photoBoothBasicSelected,
    photoBoothBasicPackagePriceDefault,
    photoBoothBasicPackagePrice,
    setPhotoBoothBasicPackagePrice,
    photoBoothBasicPackagePriceWithOnePhotographer,
    // All Inclusive package
    photoBoothAllInclusiveSelected,
    photoBoothAllInclusivePriceDefault,
    photoBoothAllInclusivePrice,
    setPhotoBoothAllInclusivePrice,
    photoBoothAllInclusivePriceWithOnePhotographer,
  } = packageConfiguratorWeddingsState;
  //#endregion

  // Photography state
  //#region One photographer
  // TODO: clean up code
  const checkForCheckoutRedirect = () => {
    const { pathname } = location;
    console.log('CHECKOUT REDIREEEEEEEEEEEEEEEEEEEEEEEEEECT');
    // calculateGrandTotal();
    console.log(
      'PATHHHHH',
      pathname,
      getLocalStorageValue('packageConfiguratorWeddings'),
      grandTotalPrice
    );
    //TODO: Redirect to home page if navigating directly to /packages/checkout/
    // if (
    //   pathname === '/packages/checkout/' &&
    //   !getLocalStorageValue('packageConfiguratorWeddings') &&
    //   !getLocalStorageValue('packageConfiguratorElopements') &&
    //   !getLocalStorageValue('packageConfiguratorStorage')
    // ) {
    //   console.log('REEEEEEEEEEEEEEEEEEEEEEEEE');
    //   // return navigate('/');
    // }
    // console.log(getLocalStorageValue('packageConfiguratorWeddings'));
    if (
      pathname === '/packages/checkout/' &&
      getLocalStorageValue('packageConfiguratorWeddings') === true &&
      (grandTotalPrice === '0.00' || grandTotalPrice === 0)
    ) {
      // return navigate('/wedding-packages/');
      return window.location.replace('/wedding-packages/');
    }
    if (
      pathname === '/packages/checkout/' &&
      getLocalStorageValue('packageConfiguratorElopements') === true &&
      (grandTotalPriceElopements === '0.00' || grandTotalPriceElopements === 0)
    ) {
      // return navigate('/wedding-packages/');
      return window.location.replace('/wedding/elopement/');
    }
    // TODO:
    // if (
    //   pathname === '/packages/checkout/' &&
    //   getLocalStorageValue('packageConfiguratorStorage') === true &&
    //   (grandTotalPriceElopements === '0.00' || grandTotalPriceElopements === 0)
    // ) {
    //   // return navigate('/wedding-packages/');
    //   return window.location.replace('/wedding/elopement/');
    // }
  };

  // Redirects back if grandTotalPrice is 0 on checkout page
  useEffect(() => {
    // try to mitigate useEffect running twice on first render
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    checkForCheckoutRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grandTotalPrice, grandTotalPriceElopements]);

  // handle selected state
  const handleOnePhotographerSelected = (ev) => {
    ev.stopPropagation();
    clearStoragePackageState();
    // TODO: modified accessability
    // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
    setOnePhotographerSelected(!onePhotographerSelected);
    // TODO: modified accessability
    // handleKeyPressed(ev.keyCode);

    // clear Videographer control state
    setOneVideographerControl('');
  };

  // handle cc images amount, and price, based on hours
  useEffect(() => {
    if (onePhotographerControl === 'decrease') {
      if (onePhotographerHour < 8) {
        setOnePhotographerCCImages(
          onePhotographerCCImages - onePhotographerCCImagesBelow8
        );

        setOnePhotographerPrice(
          onePhotographerPrice - onePhotographerPriceForBelow8
        );
      }

      if (onePhotographerHour > 8) {
        setOnePhotographerCCImages(
          onePhotographerCCImages - onePhotographerCCImagesAbove8
        );
        setOnePhotographerPrice(
          onePhotographerPrice - onePhotographerPriceForAbove8
        );
      }
    }

    if (onePhotographerControl === 'increase') {
      if (onePhotographerHour < 8) {
        setOnePhotographerCCImages(
          onePhotographerCCImages + onePhotographerCCImagesBelow8
        );
        setOnePhotographerPrice(
          onePhotographerPrice + onePhotographerPriceForBelow8
        );
      }
      if (onePhotographerHour > 8) {
        setOnePhotographerCCImages(
          onePhotographerCCImages + onePhotographerCCImagesAbove8
        );
        setOnePhotographerPrice(
          onePhotographerPrice + onePhotographerPriceForAbove8
        );
      }
    }

    if (onePhotographerHour === 8) {
      setOnePhotographerCCImages(onePhotographerCCImages8);
      setOnePhotographerPrice(onePhotographerPriceDefault);
    }

    setOnePhotographerHour(onePhotographerHour);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onePhotographerHour, onePhotographerControl]);

  const handleOnePhotographerPrice = () => {
    if (
      onePhotographerHour === 8 &&
      onePhotographerPrice !== onePhotographerPriceDefault
    ) {
      setOnePhotographerPrice(onePhotographerPriceDefault);
      setOnePhotographerCCImages(onePhotographerCCImages8);
    }

    if (
      onePhotographerHour === 7 &&
      onePhotographerPrice !==
        onePhotographerPriceDefault - onePhotographerPriceForBelow8
    ) {
      setOnePhotographerPrice(
        onePhotographerPriceDefault - onePhotographerPriceForBelow8
      );
      setOnePhotographerCCImages(
        onePhotographerCCImages - onePhotographerCCImagesBelow8
      );
    }

    if (
      onePhotographerHour === 6 &&
      onePhotographerPrice !==
        onePhotographerPriceDefault - onePhotographerPriceForBelow8 * 2
    ) {
      setOnePhotographerPrice(
        onePhotographerPriceDefault - onePhotographerPriceForBelow8 * 2
      );
      setOnePhotographerCCImages(
        onePhotographerCCImages - onePhotographerCCImagesBelow8 * 2
      );
    }

    if (onePhotographerHour > 8) {
      let correctedOnePhotographerPrice = onePhotographerPriceDefault;
      let correctedOnePhotographerCCImages = onePhotographerCCImages8;

      for (let i = 8; i < onePhotographerHour; i++) {
        correctedOnePhotographerPrice += onePhotographerPriceForAbove8;
        correctedOnePhotographerCCImages += onePhotographerCCImagesAbove8;
      }
      setOnePhotographerPrice(correctedOnePhotographerPrice);
      setOnePhotographerCCImages(correctedOnePhotographerCCImages);
    }
  };
  //#endregion

  //#region Second photographer
  // handle cc images amount and price based on hours
  useEffect(() => {
    if (secondPhotographerControl === 'decrease') {
      if (secondPhotographerHour < 8) {
        setSecondPhotographerCCImages(
          secondPhotographerCCImages - secondPhotographerCCImagesBelow8
        );

        setSecondPhotographerPrice(
          secondPhotographerPrice - secondPhotographerPriceForBelow8
        );
      }

      if (secondPhotographerHour > 8) {
        setSecondPhotographerCCImages(
          secondPhotographerCCImages - secondPhotographerCCImagesAbove8
        );
        setSecondPhotographerPrice(
          secondPhotographerPrice - secondPhotographerPriceForAbove8
        );
      }
    }

    if (secondPhotographerControl === 'increase') {
      if (secondPhotographerHour < 8) {
        setSecondPhotographerCCImages(
          secondPhotographerCCImages + secondPhotographerCCImagesBelow8
        );
        setSecondPhotographerPrice(
          secondPhotographerPrice + secondPhotographerPriceForBelow8
        );
      }
      if (secondPhotographerHour > 8) {
        setSecondPhotographerCCImages(
          secondPhotographerCCImages + secondPhotographerCCImagesAbove8
        );
        setSecondPhotographerPrice(
          secondPhotographerPrice + secondPhotographerPriceForAbove8
        );
      }
    }

    if (secondPhotographerHour === 8) {
      setSecondPhotographerCCImages(secondPhotographerCCImages8);
      setSecondPhotographerPrice(secondPhotographerPriceDefault);
    }

    setSecondPhotographerHour(secondPhotographerHour);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondPhotographerHour, secondPhotographerControl]);

  const handleSecondPhotographerPrice = () => {
    if (
      secondPhotographerHour === 8 &&
      secondPhotographerPrice !== secondPhotographerPriceDefault
    ) {
      setSecondPhotographerPrice(secondPhotographerPriceDefault);
      setSecondPhotographerCCImages(secondPhotographerCCImages8);
    }

    if (
      secondPhotographerHour === 7 &&
      secondPhotographerPrice !==
        secondPhotographerPriceDefault - secondPhotographerPriceForBelow8
    ) {
      setSecondPhotographerPrice(
        secondPhotographerPriceDefault - secondPhotographerPriceForBelow8
      );
      setSecondPhotographerCCImages(
        secondPhotographerCCImages - secondPhotographerCCImagesBelow8
      );
    }

    if (
      secondPhotographerHour === 6 &&
      secondPhotographerPrice !==
        secondPhotographerPriceDefault - secondPhotographerPriceForBelow8 * 2
    ) {
      setSecondPhotographerPrice(
        secondPhotographerPriceDefault - secondPhotographerPriceForBelow8 * 2
      );
      setSecondPhotographerCCImages(
        secondPhotographerCCImages - secondPhotographerCCImagesBelow8 * 2
      );
    }

    if (
      secondPhotographerHour === 5 &&
      secondPhotographerPrice !==
        secondPhotographerPriceDefault - secondPhotographerPriceForBelow8 * 3
    ) {
      setSecondPhotographerPrice(
        secondPhotographerPriceDefault - secondPhotographerPriceForBelow8 * 3
      );
      setSecondPhotographerCCImages(
        secondPhotographerCCImages - secondPhotographerCCImagesBelow8 * 3
      );
    }

    if (
      secondPhotographerHour === 4 &&
      secondPhotographerPrice !==
        secondPhotographerPriceDefault - secondPhotographerPriceForBelow8 * 4
    ) {
      setSecondPhotographerPrice(
        secondPhotographerPriceDefault - secondPhotographerPriceForBelow8 * 4
      );
      setSecondPhotographerCCImages(
        secondPhotographerCCImages - secondPhotographerCCImagesBelow8 * 4
      );
    }

    if (secondPhotographerHour > 8) {
      let correctedSecondPhotographerPrice = secondPhotographerPriceDefault;
      let correctedSecondPhotographerCCImages = secondPhotographerCCImages8;

      for (let i = 8; i < secondPhotographerHour; i++) {
        correctedSecondPhotographerPrice += secondPhotographerPriceForAbove8;
        correctedSecondPhotographerCCImages += secondPhotographerCCImagesAbove8;
      }
      setSecondPhotographerPrice(correctedSecondPhotographerPrice);
      setSecondPhotographerCCImages(correctedSecondPhotographerCCImages);
    }
  };
  //#endregion

  //#region Wedding albums methods
  // handle plural for all wedding albums
  useEffect(() => {
    weddingAlbum10Amount > 1
      ? setWeddingAlbum10Info('Albums')
      : setWeddingAlbum10Info('Album');

    weddingAlbum12Amount > 1
      ? setWeddingAlbum12Info('Albums')
      : setWeddingAlbum12Info('Album');

    weddingAlbum10AmountFree > 1
      ? setWeddingAlbum10AmountFreeInfo('Albums')
      : setWeddingAlbum10AmountFreeInfo('Album');

    weddingAlbum12AmountFree > 1
      ? setWeddingAlbum12AmountFreeInfo('Albums')
      : setWeddingAlbum12AmountFreeInfo('Album');

    weddingAlbum10ProfessionalDesignAmount > 1
      ? setWeddingAlbum10ProfessionalDesignInfo('Designs')
      : setWeddingAlbum10ProfessionalDesignInfo('Design');

    weddingAlbum12ProfessionalDesignAmount > 1
      ? setWeddingAlbum12ProfessionalDesignInfo('Designs')
      : setWeddingAlbum12ProfessionalDesignInfo('Design');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum10Amount,
    weddingAlbum10AmountFree,
    weddingAlbum12Amount,
    weddingAlbum12AmountFree,
    weddingAlbum10ProfessionalDesignAmount,
    weddingAlbum12ProfessionalDesignAmount,
  ]);

  // handle pricing for 10x10 wedding album
  useEffect(() => {
    if (weddingAlbum10Control === 'decrease') {
      setWeddingAlbum10Price(
        weddingAlbum10Price -
          weddingAlbum10PriceDefault -
          weddingAlbum10ShippingFee
      );
      setWeddingAlbum10ShippingFeeTotal(
        weddingAlbum10ShippingFeeTotal - weddingAlbum10ShippingFee
      );
    }
    if (weddingAlbum10Control === 'increase') {
      setWeddingAlbum10Price(
        weddingAlbum10Price +
          weddingAlbum10PriceDefault +
          weddingAlbum10ShippingFee
      );
      setWeddingAlbum10ShippingFeeTotal(
        weddingAlbum10ShippingFeeTotal + weddingAlbum10ShippingFee
      );
    }
    if (weddingAlbum10Amount === 1) {
      setWeddingAlbum10Price(
        weddingAlbum10PriceDefault + weddingAlbum10ShippingFee
      );
      setWeddingAlbum10ShippingFeeTotal(weddingAlbum10ShippingFee);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weddingAlbum10Amount]);

  // re calculate price on page refresh (weddings and checkout page)
  const handlePriceTotalWeddingAlbum10 = (w10a) => {
    if (weddingAlbum10Amount === 1) {
      setWeddingAlbum10Price(
        Number(weddingAlbum10PriceDefault + weddingAlbum10ShippingFee)
      );
    }

    let correctedWeddingAlbum10Price = Number(
      weddingAlbum10PriceDefault + weddingAlbum10ShippingFee
    );

    let correctedShippingFee = Number(weddingAlbum10ShippingFee);

    let correctedWeddingAlbum10ProfessionalDesignPrice = 0;

    for (let i = 1; i < w10a; i++) {
      correctedWeddingAlbum10Price += weddingAlbum10PriceDefault;
      correctedWeddingAlbum10Price += weddingAlbum10ShippingFee;

      correctedShippingFee += weddingAlbum10ShippingFee;

      correctedWeddingAlbum10ProfessionalDesignPrice +=
        weddingAlbum10ProfessionalDesignPrice;
    }

    setWeddingAlbum10Price(Number(correctedWeddingAlbum10Price));
    setWeddingAlbum10ShippingFeeTotal(Number(correctedShippingFee));

    setWeddingAlbum10ProfessionalDesignPriceTotal(
      Number(correctedWeddingAlbum10ProfessionalDesignPrice)
    );
  };

  // handle price total for 10x10 wedding album
  // deps on professional design
  useEffect(() => {
    setWeddingAlbum10PriceTotal(weddingAlbum10Price);
    if (weddingAlbum10ProfessionalDesignSelected) {
      setWeddingAlbum10PriceTotal(
        weddingAlbum10Price + weddingAlbum10ProfessionalDesignPriceTotal
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum10Price,
    weddingAlbum10ProfessionalDesignSelected,
    weddingAlbum10ProfessionalDesignPriceTotal,
  ]);

  // handle pricing for 12x12 wedding album
  useEffect(() => {
    if (weddingAlbum12Control === 'decrease') {
      setWeddingAlbum12Price(
        weddingAlbum12Price -
          weddingAlbum12PriceDefault -
          weddingAlbum12ShippingFee
      );
      setWeddingAlbum12ShippingFeeTotal(
        weddingAlbum12ShippingFeeTotal - weddingAlbum12ShippingFee
      );
    }
    if (weddingAlbum12Control === 'increase') {
      setWeddingAlbum12Price(
        weddingAlbum12Price +
          weddingAlbum12PriceDefault +
          weddingAlbum12ShippingFee
      );
      setWeddingAlbum12ShippingFeeTotal(
        weddingAlbum12ShippingFeeTotal + weddingAlbum12ShippingFee
      );
    }
    if (weddingAlbum12Amount === 1) {
      setWeddingAlbum12Price(
        weddingAlbum12PriceDefault + weddingAlbum12ShippingFee
      );
      setWeddingAlbum12ShippingFeeTotal(weddingAlbum12ShippingFee);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weddingAlbum12Amount]);

  // re calculate price on page refresh (weddings and checkout page)
  const handlePriceTotalWeddingAlbum12 = (w12a) => {
    if (weddingAlbum12Amount === 1) {
      setWeddingAlbum12Price(
        Number(weddingAlbum12PriceDefault + weddingAlbum12ShippingFee)
      );
    }

    let correctedWeddingAlbum12Price = Number(
      weddingAlbum12PriceDefault + weddingAlbum12ShippingFee
    );

    let correctedShippingFee = Number(weddingAlbum12ShippingFee);

    let correctedWeddingAlbum12ProfessionalDesignPrice = 0;

    for (let i = 1; i < w12a; i++) {
      correctedWeddingAlbum12Price += weddingAlbum12PriceDefault;
      correctedWeddingAlbum12Price += weddingAlbum12ShippingFee;

      correctedShippingFee += weddingAlbum12ShippingFee;

      correctedWeddingAlbum12ProfessionalDesignPrice +=
        weddingAlbum12ProfessionalDesignPrice;
    }

    setWeddingAlbum12Price(Number(correctedWeddingAlbum12Price));
    setWeddingAlbum12ShippingFeeTotal(Number(correctedShippingFee));

    setWeddingAlbum12ProfessionalDesignPriceTotal(
      Number(correctedWeddingAlbum12ProfessionalDesignPrice)
    );
  };

  // handle price total for 12x12 wedding album
  // deps on professional design
  useEffect(() => {
    setWeddingAlbum12PriceTotal(weddingAlbum12Price);
    if (weddingAlbum12ProfessionalDesignSelected) {
      setWeddingAlbum12PriceTotal(
        weddingAlbum12Price + weddingAlbum12ProfessionalDesignPriceTotal
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum12Price,
    weddingAlbum12ProfessionalDesignSelected,
    weddingAlbum12ProfessionalDesignPriceTotal,
  ]);

  // handle 'professional design' amount and price
  // deps on 'professional design' selected state, album amount
  useEffect(() => {
    if (!uriChange) {
      if (weddingAlbum10ProfessionalDesignSelected) {
        setWeddingAlbum10ProfessionalDesignPriceTotal(
          weddingAlbum10ProfessionalDesignPrice *
            weddingAlbum10ProfessionalDesignAmount
        );
        // setWeddingAlbum10ProfessionalDesignAmount(weddingAlbum10Amount);
      }
      if (!weddingAlbum10ProfessionalDesignSelected) {
        setWeddingAlbum10ProfessionalDesignPriceTotal(
          weddingAlbum10ProfessionalDesignPrice *
            weddingAlbum10ProfessionalDesignAmount
        );
        setWeddingAlbum10ProfessionalDesignAmount(weddingAlbum10Amount);
        setWeddingAlbum10AmountFree(0);
      }

      if (weddingAlbum12ProfessionalDesignSelected) {
        setWeddingAlbum12ProfessionalDesignPriceTotal(
          weddingAlbum12ProfessionalDesignPrice * weddingAlbum12Amount
        );
        // setWeddingAlbum12ProfessionalDesignAmount(weddingAlbum12Amount);
      }
      if (!weddingAlbum12ProfessionalDesignSelected) {
        setWeddingAlbum12ProfessionalDesignPriceTotal(
          weddingAlbum12ProfessionalDesignPrice * weddingAlbum12Amount
        );
        setWeddingAlbum12ProfessionalDesignAmount(weddingAlbum12Amount);
        setWeddingAlbum12AmountFree(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum10Amount,
    weddingAlbum10ProfessionalDesignAmount,
    weddingAlbum12Amount,
    weddingAlbum12ProfessionalDesignAmount,
    weddingAlbum10ProfessionalDesignSelected,
    weddingAlbum12ProfessionalDesignSelected,
  ]);

  // handle professional design amount and free DIY amount
  useEffect(() => {
    // if (!uriChange) {
    if (weddingAlbum10ProfessionalDesignAmount > weddingAlbum10Amount) {
      setWeddingAlbum10ProfessionalDesignAmount(weddingAlbum10Amount);
    }
    if (weddingAlbum12ProfessionalDesignAmount > weddingAlbum12Amount) {
      setWeddingAlbum12ProfessionalDesignAmount(weddingAlbum12Amount);
    }
    if (weddingAlbum10ProfessionalDesignAmount > 0) {
      setWeddingAlbum10AmountFree(
        weddingAlbum10Amount - weddingAlbum10ProfessionalDesignAmount
      );
    }
    if (weddingAlbum12ProfessionalDesignAmount > 0) {
      setWeddingAlbum12AmountFree(
        weddingAlbum12Amount - weddingAlbum12ProfessionalDesignAmount
      );
    }
    if (weddingAlbum10ProfessionalDesignAmount === 0) {
      setWeddingAlbum10ProfessionalDesignSelected(false);
      setWeddingAlbum10AmountFree(0);
    }
    if (weddingAlbum12ProfessionalDesignAmount === 0) {
      setWeddingAlbum12ProfessionalDesignSelected(false);
      setWeddingAlbum12AmountFree(0);
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum10Amount,
    weddingAlbum12Amount,
    weddingAlbum10AmountFree,
    weddingAlbum12AmountFree,
    weddingAlbum10ProfessionalDesignAmount,
    weddingAlbum12ProfessionalDesignAmount,
  ]);

  // handle wedding album price total and
  // 'professional design' amount
  // deps wedding album amount
  useEffect(() => {
    if (!uriChange) {
      setWeddingAlbum10ProfessionalDesignPriceTotal(
        weddingAlbum10ProfessionalDesignPrice *
          weddingAlbum10ProfessionalDesignAmount
      );

      if (weddingAlbum10ProfessionalDesignAmount === 0) {
        setWeddingAlbum10ProfessionalDesignSelected(false);
        setWeddingAlbum10AmountFree(0);
      }

      setWeddingAlbum12ProfessionalDesignPriceTotal(
        weddingAlbum12ProfessionalDesignPrice *
          weddingAlbum12ProfessionalDesignAmount
      );

      if (weddingAlbum12ProfessionalDesignAmount === 0) {
        setWeddingAlbum12ProfessionalDesignSelected(false);
        setWeddingAlbum12AmountFree(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum10ProfessionalDesignPriceTotal,
    weddingAlbum12ProfessionalDesignPriceTotal,
    weddingAlbum10ProfessionalDesignAmount,
    weddingAlbum12ProfessionalDesignAmount,
  ]);
  //#endregion

  //#region Photo retouching state
  useEffect(() => {
    if (
      photoRetouching10Selected ||
      photoRetouching20Selected ||
      photoRetouching30Selected
    ) {
      setPhotoRetouchingSelected(true);
    } else {
      setPhotoRetouchingSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    photoRetouching10Selected,
    photoRetouching20Selected,
    photoRetouching30Selected,
  ]);

  // handle photo retouching pricing
  const handlePhotoRetouchingPricing = () => {
    if (photoRetouching10Selected) {
      setPhotoRetouchingPackageInfo('10 retouched photos');
      setPhotoRetouchingPackagePrice(retouchedPhotos10Price);
    }
    if (photoRetouching20Selected) {
      setPhotoRetouchingPackageInfo('20 retouched photos');
      setPhotoRetouchingPackagePrice(retouchedPhotos20Price);
    }
    if (photoRetouching30Selected) {
      setPhotoRetouchingPackageInfo('30 retouched photos');
      setPhotoRetouchingPackagePrice(retouchedPhotos30Price);
    }
  };
  //#endregion

  //#region Engagement photoshoot state
  useEffect(() => {
    if (engagementOneHourSelected || engagementTwoHoursSelected) {
      setEngagementPhotoShootSelected(true);
    } else {
      setEngagementPhotoShootSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engagementOneHourSelected, engagementTwoHoursSelected]);

  useEffect(() => {
    if (engagementOneHourSelected) {
      setEngagementPhotoShootPackageInfo('1hr engagement photoshoot');
    }
    if (engagementTwoHoursSelected) {
      setEngagementPhotoShootPackageInfo('2hrs engagement photoshoot');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engagementPhotoShootSelected]);

  // handle engagements pricing
  const handleEngagementsPricing = () => {
    if (engagementOneHourSelected) {
      setEngagementPhotoShootPackageInfo('1hr engagement photoshoot');
      setEngagementPhotoShootPrice(engagementOneHourPrice);
    }
    if (engagementTwoHoursSelected) {
      setEngagementPhotoShootPackageInfo('2hrs engagement photoshoot');
      setEngagementPhotoShootPrice(engagementTwoHoursPrice);
    }
  };
  //#endregion

  // Videography state
  //#region One videographer
  // handle selected state
  const handleOneVideographerSelected = (ev) => {
    clearStoragePackageState();
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      setOneVideographerSelected(!oneVideographerSelected);
      setCinematographyPrice(cinematographyPriceWithOneVideographer);
      handleKeyPressed(ev.keyCode);
    }
  };

  // handle price based on hours
  useEffect(() => {
    setOneVideographerHour(oneVideographerHour);

    if (oneVideographerControl === 'decrease') {
      if (oneVideographerHour < 8) {
        setOneVideographerPrice(
          oneVideographerPrice - oneVideographerPriceForBelow8
        );
      }

      if (oneVideographerHour > 8) {
        setOneVideographerPrice(
          oneVideographerPrice - oneVideographerPriceForAbove8
        );
      }
    }

    if (oneVideographerControl === 'increase') {
      if (oneVideographerHour < 8) {
        setOneVideographerPrice(
          oneVideographerPrice + oneVideographerPriceForBelow8
        );
      }
      if (oneVideographerHour > 8) {
        setOneVideographerPrice(
          oneVideographerPrice + oneVideographerPriceForAbove8
        );
      }
    }

    if (oneVideographerHour === 8 && !onePhotographerSelected) {
      setOneVideographerPrice(oneVideographerPriceDefault);
    }

    if (oneVideographerHour === 8 && onePhotographerSelected) {
      setOneVideographerPrice(oneVideographerPriceWithOnePhotographer);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneVideographerHour, oneVideographerControl, onePhotographerSelected]);

  const handleOneVideographerPrice = () => {
    if (!onePhotographerSelected) {
      if (
        oneVideographerHour === 8 &&
        oneVideographerPrice !== oneVideographerPriceDefault
      ) {
        setOneVideographerPrice(oneVideographerPriceDefault);
      }

      if (
        oneVideographerHour === 7 &&
        oneVideographerPrice !==
          oneVideographerPrice - oneVideographerPriceForBelow8
      ) {
        setOneVideographerPrice(
          oneVideographerPriceDefault - oneVideographerPriceForBelow8
        );
      }

      if (
        oneVideographerHour === 6 &&
        oneVideographerPrice !==
          oneVideographerPrice - oneVideographerPriceForBelow8 * 2
      ) {
        setOneVideographerPrice(
          oneVideographerPriceDefault - oneVideographerPriceForBelow8 * 2
        );
      }

      if (
        oneVideographerHour === 5 &&
        oneVideographerPrice !==
          oneVideographerPriceDefault - oneVideographerPriceForBelow8 * 3
      ) {
        setOneVideographerPrice(
          oneVideographerPriceDefault - oneVideographerPriceForBelow8 * 3
        );
      }

      if (
        oneVideographerHour === 4 &&
        oneVideographerPrice !==
          oneVideographerPriceDefault - oneVideographerPriceForBelow8 * 4
      ) {
        setOneVideographerPrice(
          oneVideographerPriceDefault - oneVideographerPriceForBelow8 * 4
        );
      }
    }

    if (onePhotographerSelected) {
      if (
        oneVideographerHour === 8 &&
        oneVideographerPrice !== oneVideographerPriceWithOnePhotographer
      ) {
        setOneVideographerPrice(oneVideographerPriceWithOnePhotographer);
      }

      if (
        oneVideographerHour === 7 &&
        oneVideographerPrice !==
          oneVideographerPriceWithOnePhotographer -
            oneVideographerPriceForBelow8
      ) {
        setOneVideographerPrice(
          oneVideographerPriceWithOnePhotographer -
            oneVideographerPriceForBelow8
        );
      }

      if (
        oneVideographerHour === 6 &&
        oneVideographerPrice !==
          oneVideographerPriceWithOnePhotographer -
            oneVideographerPriceForBelow8 * 2
      ) {
        setOneVideographerPrice(
          oneVideographerPriceWithOnePhotographer -
            oneVideographerPriceForBelow8 * 2
        );
      }

      if (
        oneVideographerHour === 5 &&
        oneVideographerPrice !==
          oneVideographerPriceWithOnePhotographer -
            oneVideographerPriceForBelow8 * 3
      ) {
        setOneVideographerPrice(
          oneVideographerPriceWithOnePhotographer -
            oneVideographerPriceForBelow8 * 3
        );
      }

      if (
        oneVideographerHour === 4 &&
        oneVideographerPrice !==
          oneVideographerPriceWithOnePhotographer -
            oneVideographerPriceForBelow8 * 4
      ) {
        setOneVideographerPrice(
          oneVideographerPriceWithOnePhotographer -
            oneVideographerPriceForBelow8 * 4
        );
      }
    }

    if (oneVideographerHour > 8) {
      let correctedOneVideographerPrice;

      if (onePhotographerSelected) {
        correctedOneVideographerPrice = oneVideographerPriceWithOnePhotographer;
      } else {
        correctedOneVideographerPrice = oneVideographerPriceDefault;
      }

      for (let i = 8; i < oneVideographerHour; i++) {
        correctedOneVideographerPrice += oneVideographerPriceForAbove8;
      }
      setOneVideographerPrice(correctedOneVideographerPrice);
    }
  };
  //#endregion

  //#region Second videographer
  // handle price based on hours
  useEffect(() => {
    // setSecondVideographerHour(secondVideographerHour);

    if (secondVideographerControl === 'decrease') {
      if (secondVideographerHour < 8) {
        setSecondVideographerPrice(
          secondVideographerPrice - secondVideographerPriceForBelow8
        );
      }

      if (secondVideographerHour > 8) {
        setSecondVideographerPrice(
          secondVideographerPrice - secondVideographerPriceForAbove8
        );
      }
    }

    if (secondVideographerControl === 'increase') {
      if (secondVideographerHour < 8) {
        setSecondVideographerPrice(
          secondVideographerPrice + secondVideographerPriceForBelow8
        );
      }
      if (secondVideographerHour > 8) {
        setSecondVideographerPrice(
          secondVideographerPrice + secondVideographerPriceForAbove8
        );
      }
    }

    if (secondVideographerHour === 8) {
      setSecondVideographerPrice(secondVideographerPriceDefault);
    }

    setSecondVideographerHour(secondVideographerHour);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [secondVideographerHour, secondVideographerControl]);

  const handleSecondVideographerPrice = () => {
    if (
      secondVideographerHour === 8 &&
      secondVideographerPrice !== secondVideographerPriceDefault
    ) {
      setSecondVideographerPrice(secondVideographerPriceDefault);
    }

    if (
      secondVideographerHour === 7 &&
      secondVideographerPrice !==
        secondVideographerPriceDefault - secondVideographerPriceForBelow8
    ) {
      setSecondVideographerPrice(
        secondVideographerPriceDefault - secondVideographerPriceForBelow8
      );
    }

    if (
      secondVideographerHour === 6 &&
      secondVideographerPrice !==
        secondVideographerPriceDefault - secondVideographerPriceForBelow8 * 2
    ) {
      setSecondVideographerPrice(
        secondVideographerPriceDefault - secondVideographerPriceForBelow8 * 2
      );
    }

    if (
      secondVideographerHour === 5 &&
      secondVideographerPrice !==
        secondVideographerPriceDefault - secondVideographerPriceForBelow8 * 3
    ) {
      setSecondVideographerPrice(
        secondVideographerPriceDefault - secondVideographerPriceForBelow8 * 3
      );
    }

    if (
      secondVideographerHour === 4 &&
      secondVideographerPrice !==
        secondVideographerPriceDefault - secondVideographerPriceForBelow8 * 4
    ) {
      setSecondVideographerPrice(
        secondVideographerPriceDefault - secondVideographerPriceForBelow8 * 4
      );
    }

    if (secondVideographerHour > 8) {
      let correctedSecondVideographerPrice = secondVideographerPriceDefault;

      for (let i = 8; i < secondVideographerHour; i++) {
        correctedSecondVideographerPrice += secondVideographerPriceForAbove8;
      }
      setSecondVideographerPrice(correctedSecondVideographerPrice);
    }
  };
  //#endregion

  //#region ## FIX ## Remove premium video package if no video editing
  // options selected but keep it on page refresh if it was added
  // this also fixes the issue on page refresh in new browser window
  // where the premium videopackage is missing if it was added in the uri
  // TODO: recheck this
  useEffect(() => {
    // const storedValue = getLocalStorageValue('premiumVideoPackageSelected');

    if (
      oneVideographerSelected === true &&
      cinematographySelected === false &&
      trailerOnlySelected === false &&
      extendedTrailerSelected === false &&
      traditionalSelected === false &&
      // storedValue === false &&
      premiumVideoPackageSelected === false
      // premiumVideoPackageSelected === true
    ) {
      setPremiumVideoPackageSelected(false);
    } else if (
      oneVideographerSelected === true &&
      premiumVideoPackageSelected === true &&
      // storedValue === true &&
      (cinematographySelected === true ||
        trailerOnlySelected === true ||
        extendedTrailerSelected === true ||
        traditionalSelected === true)
    ) {
      setPremiumVideoPackageSelected(true);
    } else {
      setPremiumVideoPackageSelected(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    oneVideographerSelected,
    cinematographySelected,
    trailerOnlySelected,
    extendedTrailerSelected,
    traditionalSelected,
  ]);
  //#endregion

  //#region ## FIX ## Remove premium video package if no video editing
  // options selected but keep it on page refresh if it was added
  // this also fixes the issue on page refresh in new browser window
  // where the premium videopackage is missing if it was added in the uri
  // TODO: recheck this
  useEffect(() => {
    // const storedValue = getLocalStorageValue('premiumPhotoPackageSelected');

    if (
      onePhotographerSelected === true &&
      // storedValue === false &&
      premiumPhotoPackageSelected === false
      // premiumPhotoPackageSelected === true
    ) {
      setPremiumPhotoPackageSelected(false);
    }
    // if (onePhotographerSelected === true && storedValue === true) {
    if (
      onePhotographerSelected === true &&
      premiumPhotoPackageSelected === true
    ) {
      setPremiumPhotoPackageSelected(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onePhotographerSelected]);
  //#endregion

  //#region Handle cinematography, trailerOnly, extendedTrailer, traditional - pricing
  useEffect(() => {
    if (secondVideographerSelected) {
      setCinematographyPrice(cinematographyPriceWithSecondVideographer);
      setTrailerOnlyPrice(trailerOnlyPriceWithSecondVideographer);
      setExtendedTrailerPrice(extendedTrailerPriceWithSecondVideographer);
      setTraditionalPrice(traditionalPriceWithSecondVideographer);
    } else {
      setCinematographyPrice(cinematographyPriceWithOneVideographer);
      setTrailerOnlyPrice(trailerOnlyPriceWithOneVideographer);
      setExtendedTrailerPrice(extendedTrailerPriceWithOneVideographer);
      setTraditionalPrice(traditionalPriceWithOneVideographer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneVideographerSelected, secondVideographerSelected]);

  //#endregion

  //#region Premium Packages
  // Setting title in PackageModal
  const [isPremiumPackageAdded, setIsPremiumPackageAdded] = useState(false);

  // set title to either title or discountTitle
  // and adjust pricing of video
  useEffect(() => {
    if (!premiumPhotoPackageSelected) {
      setIsPremiumPackageAdded(false);
      setPremiumVideoPackagePopupTitle(premiumVideoPackagePopupTitleDefault);
      setPremiumVideoPackagePrice(premiumVideoPackagePriceDefault);
    }
    if (premiumPhotoPackageSelected || extendedTrailerSelected) {
      setIsPremiumPackageAdded(true);
      setPremiumVideoPackagePopupTitle(premiumVideoPackagePopupTitleDiscount);
      setPremiumVideoPackagePrice(premiumVideoPackagePriceDiscount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [premiumPhotoPackageSelected, extendedTrailerSelected]);
  //#endregion

  //#region Photo Booth
  // handle photobooth pricing in sidebar
  useEffect(() => {
    if (photoBoothBasicSelected) {
      setPhotoBoothPackagePrice(photoBoothBasicPackagePrice);
    }
    if (photoBoothAllInclusiveSelected) {
      setPhotoBoothPackagePrice(photoBoothAllInclusivePrice);
    }
    if (onePhotographerSelected && photoBoothBasicSelected) {
      setPhotoBoothPackagePrice(photoBoothBasicPackagePriceWithOnePhotographer);
    }
    if (onePhotographerSelected && photoBoothAllInclusiveSelected) {
      setPhotoBoothPackagePrice(photoBoothAllInclusivePriceWithOnePhotographer);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onePhotographerSelected,
    photoBoothBasicSelected,
    photoBoothAllInclusiveSelected,
  ]);

  // handle photobooth discount
  // when one photographer selected
  // when one photographer and
  // one videographer selected (disabled for now)
  useEffect(() => {
    if (onePhotographerSelected) {
      setPhotoBoothBasicPackagePrice(
        photoBoothBasicPackagePriceWithOnePhotographer
      );
      setPhotoBoothAllInclusivePrice(
        photoBoothAllInclusivePriceWithOnePhotographer
      );
      if (photoBoothBasicSelected) {
        setPhotoBoothPackagePrice(
          photoBoothBasicPackagePriceWithOnePhotographer
        );
      }
      if (photoBoothAllInclusiveSelected) {
        setPhotoBoothPackagePrice(
          photoBoothAllInclusivePriceWithOnePhotographer
        );
      }
    }

    // one videographer selected (disabled for now)
    // if (onePhotographerSelected && oneVideographerSelected) {
    //   console.log('PB OP & OV');
    //   setPhotoBoothBasicPackagePrice(
    //     photoBoothBasicPackagePriceWithOnePhotographerAndOneVideographer
    //   );
    //   setPhotoBoothAllInclusivePrice(
    //     photoBoothAllInclusivePriceWithOnePhotographerAndOneVideographer
    //   );
    //   if (photoBoothBasicSelected) {
    //     setPhotoBoothPackagePrice(
    //       photoBoothBasicPackagePriceWithOnePhotographerAndOneVideographer
    //     );
    //   }
    //   if (photoBoothAllInclusiveSelected) {
    //     setPhotoBoothPackagePrice(
    //       photoBoothAllInclusivePriceWithOnePhotographerAndOneVideographer
    //     );
    //   }
    // }

    if (
      ((photoBoothBasicSelected || photoBoothAllInclusiveSelected) &&
        !onePhotographerSelected) ||
      (!photoBoothBasicSelected &&
        !photoBoothAllInclusiveSelected &&
        !onePhotographerSelected)
    ) {
      setPhotoBoothBasicPackagePrice(photoBoothBasicPackagePriceDefault);
      setPhotoBoothAllInclusivePrice(photoBoothAllInclusivePriceDefault);

      if (photoBoothBasicSelected) {
        setPhotoBoothPackagePrice(photoBoothBasicPackagePriceDefault);
      }
      if (photoBoothAllInclusiveSelected) {
        setPhotoBoothPackagePrice(photoBoothAllInclusivePriceDefault);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onePhotographerSelected,
    oneVideographerSelected,
    photoBoothBasicSelected,
    photoBoothAllInclusiveSelected,
  ]);
  //#endregion

  //#region Storage
  // handle storage package
  const [storageInfo] = useState(storage.info);
  const [storagePackagePrice, setStoragePackagePrice] = useState(0);

  // photo storage package
  const [storagePhotoPackageInfo] = useState(storage.photo.info);
  const [storagePhotoPackageOption1PopupText] = useState(
    storage.photo.options.option1PopupText
  );
  const [storagePhotoPackageOption1SidebarText] = useState(
    storage.photo.options.option1SidebarText
  );
  const [storagePhotoPackageOption1Price] = useState(
    storage.photo.options.option1Price
  );
  const [storagePhotoPackageOption2PopupText] = useState(
    storage.photo.options.option2PopupText
  );
  const [storagePhotoPackageOption2SidebarText] = useState(
    storage.photo.options.option2SidebarText
  );
  const [storagePhotoPackageOption2Price] = useState(
    storage.photo.options.option2Price
  );
  const [storagePhotoPackageOption3PopupText] = useState(
    storage.photo.options.option3PopupText
  );
  const [storagePhotoPackageOption3SidebarText] = useState(
    storage.photo.options.option3SidebarText
  );
  const [storagePhotoPackageOption3Price] = useState(
    storage.photo.options.option3Price
  );

  // video storage package
  const [storageVideoPackageInfo] = useState(storage.video.info);
  const [storageVideoPackageOption1PopupText] = useState(
    storage.video.options.option1PopupText
  );
  const [storageVideoPackageOption1SidebarText] = useState(
    storage.video.options.option1SidebarText
  );
  const [storageVideoPackageOption1Price] = useState(
    storage.video.options.option1Price
  );
  const [storageVideoPackageOption2PopupText] = useState(
    storage.video.options.option2PopupText
  );
  const [storageVideoPackageOption2SidebarText] = useState(
    storage.video.options.option2SidebarText
  );
  const [storageVideoPackageOption2Price] = useState(
    storage.video.options.option2Price
  );
  const [storageVideoPackageOption3PopupText] = useState(
    storage.video.options.option3PopupText
  );
  const [storageVideoPackageOption3SidebarText] = useState(
    storage.video.options.option3SidebarText
  );
  const [storageVideoPackageOption3Price] = useState(
    storage.video.options.option3Price
  );

  // photo and video storage package
  const [storagePhotoAndVideoPackageInfo] = useState(storage.photoVideo.info);
  const [storagePhotoAndVideoPackageOption1PopupText] = useState(
    storage.photoVideo.options.option1PopupText
  );
  const [storagePhotoAndVideoPackageOption1SidebarText] = useState(
    storage.photoVideo.options.option1SidebarText
  );
  const [storagePhotoAndVideoPackageOption1Price] = useState(
    storage.photoVideo.options.option1Price
  );
  const [storagePhotoAndVideoPackageOption2PopupText] = useState(
    storage.photoVideo.options.option2PopupText
  );
  const [storagePhotoAndVideoPackageOption2SidebarText] = useState(
    storage.photoVideo.options.option2SidebarText
  );
  const [storagePhotoAndVideoPackageOption2Price] = useState(
    storage.photoVideo.options.option2Price
  );
  const [storagePhotoAndVideoPackageOption3PopupText] = useState(
    storage.photoVideo.options.option3PopupText
  );
  const [storagePhotoAndVideoPackageOption3SidebarText] = useState(
    storage.photoVideo.options.option3SidebarText
  );
  const [storagePhotoAndVideoPackageOption3Price] = useState(
    storage.photoVideo.options.option3Price
  );

  // handle storage state
  const [storagePackageSelected, setStoragePackageSelected] = useState(false);

  // TODO: accessibility state
  const handleStoragePackageSelected = () => {
    setStoragePackageSelected(!storagePackageSelected);
  };

  // clear storage package state
  useEffect(() => {
    if (storagePackageSelected === false) {
      clearStoragePackageState();
    }
  }, [
    storagePackageSelected,
    onePhotographerSelected,
    oneVideographerSelected,
  ]);

  // clear storage package state on package change
  // examp. if storage is selected with one photographer
  // then when one videographer is added clear storage package state
  const clearStoragePackageState = () => {
    setCheckedStorageValue(null);
    setCheckedStorageValueModal(null);
    setToggleCheckoutBtnInfo(false);
    setStoragePackageSelected(false);
    setStoragePackagePrice(0);
  };

  const [storagePackageType, setStoragePackageType] = useState('');

  const handleStoragePackage = (type) => {
    setStoragePackageType(type);
  };
  //#endregion

  //#region Storage in package modal
  // state is separate for weddings and elopements, handleStoragePackage()
  const [checkedStorageValueModal, setCheckedStorageValueModal] =
    useState(null);
  const [checkedStorageValue, setCheckedStorageValue] = useState(null);
  const [toggleCheckoutBtnInfo, setToggleCheckoutBtnInfo] = useState(false);

  // handle storage package inputs
  const handleStoragePackageChange = (price, ev) => {
    handlePreventDefault(ev);
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      if (checkedStorageValue === ev.target.value) {
        setCheckedStorageValue(null);
        setCheckedStorageValueModal(null);
        setToggleCheckoutBtnInfo(false);
        setStoragePackageSelected(false);
        setStoragePackagePrice(0);
      } else {
        setCheckedStorageValue(ev.target.value);
        setCheckedStorageValueModal(ev.target.value);
        setToggleCheckoutBtnInfo(true);
        setStoragePackageSelected(true);
        setStoragePackagePrice(Number(price));
      }
      handleKeyPressed(ev.keyCode);
    }
  };

  // sync inputs in modal and in sidebar with state
  useEffect(() => {
    if (checkedStorageValueModal !== null) {
      setCheckedStorageValue(checkedStorageValueModal);
    }
  }, [checkedStorageValueModal, checkedStorageValue, storagePackageType]);

  const handleStoragePrice = () => {
    if (storagePackageSelected) {
      if (storagePackageType === 'photo') {
        if (checkedStorageValue === '1') {
          setStoragePackagePrice(storagePhotoPackageOption1Price);
        }
        if (checkedStorageValue === '2') {
          setStoragePackagePrice(storagePhotoPackageOption2Price);
        }
        if (checkedStorageValue === '3') {
          setStoragePackagePrice(storagePhotoPackageOption3Price);
        }
      }
      if (storagePackageType === 'video') {
        if (checkedStorageValue === '1') {
          setStoragePackagePrice(storageVideoPackageOption1Price);
        }
        if (checkedStorageValue === '2') {
          setStoragePackagePrice(storageVideoPackageOption2Price);
        }
        if (checkedStorageValue === '3') {
          setStoragePackagePrice(storageVideoPackageOption3Price);
        }
      }
      if (storagePackageType === 'photoVideo') {
        if (checkedStorageValue === '1') {
          setStoragePackagePrice(storagePhotoAndVideoPackageOption1Price);
        }
        if (checkedStorageValue === '2') {
          setStoragePackagePrice(storagePhotoAndVideoPackageOption2Price);
        }
        if (checkedStorageValue === '3') {
          setStoragePackagePrice(storagePhotoAndVideoPackageOption3Price);
        }
      }
    }
  };

  //#region Premium Modal
  const [premiumPhotoPackageModalActive, setPremiumPhotoPackageModalActive] =
    useState(false);

  // TODO: accessiblity state
  const togglePremiumPhotoPackageModal = (ev) => {
    ev.stopPropagation();
    setPremiumPhotoPackageModalActive(!premiumPhotoPackageModalActive);
  };

  const [premiumVideoPackageModalActive, setPremiumVideoPackageModalActive] =
    useState(false);

  // TODO: accessiblity state
  const togglePremiumVideoPackageModal = () => {
    setPremiumVideoPackageModalActive(!premiumVideoPackageModalActive);
  };
  //#endregion

  //#region Update Premium Video description,
  // filter wordpress description data
  // if extended trailer selected
  const [
    premiumVideoPackageDescriptionFiltered,
    setPremiumVideoPackageDescriptionFiltered,
  ] = useState();

  useEffect(() => {
    const changeDescription = (option) => {
      if (typeof window !== 'undefined') {
        let parser = new DOMParser();
        let doc = parser.parseFromString(
          premiumVideoPackageDescriptionDefault,
          'text/html'
        );

        let liElements = doc.querySelectorAll('li');
        let strongElement = document.createElement('strong');
        strongElement.textContent = liElements[1].textContent;

        if (option === 'trailerAndTraditional') {
          liElements[0].textContent = ''; // Clear the existing text
          strongElement.textContent =
            'Extended Trailer / Cinematography Sound Upgrade';
          liElements[0].appendChild(strongElement);
        }

        if (option === 'traditional') {
          liElements[0].textContent = ''; // Clear the existing text
          strongElement.textContent = 'Cinematography Sound Upgrade';
          liElements[0].appendChild(strongElement);
        }

        // Add a strong tag inside the second li element
        if (option === 'extendedTrailer') {
          if (liElements[1]) {
            // Remove the first li element
            if (liElements[0]) {
              liElements[0].remove();
            }
            liElements[1].textContent = ''; // Clear the existing text
            liElements[1].appendChild(strongElement);
          }
        }
        // Extract the updated HTML
        setPremiumVideoPackageDescriptionFiltered(doc.body.innerHTML);
      }
    };

    if (trailerOnlySelected && traditionalSelected) {
      changeDescription('trailerAndTraditional');
      setPremiumVideoPackageDescription(premiumVideoPackageDescriptionFiltered);
    } else if (traditionalSelected) {
      changeDescription('traditional');
      setPremiumVideoPackageDescription(premiumVideoPackageDescriptionFiltered);
    } else if (extendedTrailerSelected) {
      changeDescription('extendedTrailer');
      setPremiumVideoPackageDescription(premiumVideoPackageDescriptionFiltered);
    } else {
      setPremiumVideoPackageDescription(premiumVideoPackageDescriptionDefault);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    premiumVideoPackageDescriptionFiltered,
    premiumVideoPackageDescriptionDefault,
    cinematographySelected,
    trailerOnlySelected,
    traditionalSelected,
    extendedTrailerSelected,
  ]);

  //#region Elopements data
  const onePhotographerElopements =
    configuratorElopements.photography.onePhotographer;
  const weddingAlbum10Elopements =
    configuratorElopements.photography.weddingAlbum.by10;
  const weddingAlbum12Elopements =
    configuratorElopements.photography.weddingAlbum.by12;
  // const weddingAlbumInfoModalElopements =
  //   configuratorElopements.photography.weddingAlbum.weddingAlbumInfo;
  // const professionalAlbumDesignInfoElopements =
  //   configuratorElopements.photography.weddingAlbum.professionalAlbumDesignInfo;
  const oneVideographerElopements =
    configuratorElopements.videography.oneVideographer;
  const officiantElopements = configuratorElopements.officiant;
  const storageElopements = configuratorElopements.storage;

  const socialMediaPackageElopements =
    configuratorElopements.socialMediaPackage;

  const premiumPhotoPackageElopements =
    configuratorElopements.premiumPhotoPackage;
  const premiumVideoPackageElopements =
    configuratorElopements.premiumVideoPackage;

  //#region Configurator Elopements State
  // pack graphql data into an object
  const graphqlDataElopements = {
    onePhotographerElopements: onePhotographerElopements,
    weddingAlbum10Elopements: weddingAlbum10Elopements,
    weddingAlbum12Elopements: weddingAlbum12Elopements,
    premiumPhotoPackageElopements: premiumPhotoPackageElopements,
    oneVideographerElopements: oneVideographerElopements,
    premiumVideoPackageElopements: premiumVideoPackageElopements,
    socialMediaPackageElopements: socialMediaPackageElopements,
    officiantElopements: officiantElopements,
  };
  // Configurator Elopements State
  const packageConfiguratorElopementsState =
    usePackageConfiguratorElopementsState(graphqlDataElopements);

  const {
    // One Photographer
    onePhotographerSelectedElopements,
    setOnePhotographerSelectedElopements,
    onePhotographerControlElopements,
    onePhotographerHourElopements,
    setOnePhotographerHourElopements,
    onePhotographerPriceDefaultElopements,
    onePhotographerPriceElopements,
    setOnePhotographerPriceElopements,
    onePhotographerPricePerHourElopements,
    // Wedding albums
    uriChangeElopements,
    // 10x10
    weddingAlbum10PriceElopements,
    setWeddingAlbum10PriceElopements,
    weddingAlbum10PriceTotalElopements,
    setWeddingAlbum10PriceTotalElopements,
    weddingAlbum10SelectedElopements,
    setWeddingAlbum10SelectedElopements,
    weddingAlbum10AmountElopements,
    weddingAlbum10AmountFreeElopements,
    setWeddingAlbum10AmountFreeElopements,
    weddingAlbum10ProfessionalDesignAmountElopements,
    setWeddingAlbum10ProfessionalDesignAmountElopements,
    weddingAlbum10ProfessionalDesignSelectedElopements,
    setWeddingAlbum10ProfessionalDesignSelectedElopements,
    weddingAlbum10ControlElopements,
    setWeddingAlbum10InfoElopements,
    setWeddingAlbum10AmountFreeInfoElopements,
    setWeddingAlbum10ProfessionalDesignInfoElopements,
    weddingAlbum10PriceDefaultElopements,
    weddingAlbum10ShippingFeeElopements,
    weddingAlbum10ShippingFeeTotalElopements,
    setWeddingAlbum10ShippingFeeTotalElopements,
    weddingAlbum10ProfessionalDesignPriceElopements,
    weddingAlbum10ProfessionalDesignPriceTotalElopements,
    setWeddingAlbum10ProfessionalDesignPriceTotalElopements,
    // 12x12
    weddingAlbum12PriceElopements,
    setWeddingAlbum12PriceElopements,
    weddingAlbum12PriceTotalElopements,
    setWeddingAlbum12PriceTotalElopements,
    weddingAlbum12SelectedElopements,
    setWeddingAlbum12SelectedElopements,
    weddingAlbum12AmountElopements,
    weddingAlbum12AmountFreeElopements,
    setWeddingAlbum12AmountFreeElopements,
    weddingAlbum12ProfessionalDesignAmountElopements,
    setWeddingAlbum12ProfessionalDesignAmountElopements,
    weddingAlbum12ProfessionalDesignSelectedElopements,
    setWeddingAlbum12ProfessionalDesignSelectedElopements,
    weddingAlbum12ControlElopements,
    setWeddingAlbum12InfoElopements,
    setWeddingAlbum12AmountFreeInfoElopements,
    setWeddingAlbum12ProfessionalDesignInfoElopements,
    weddingAlbum12PriceDefaultElopements,
    weddingAlbum12ShippingFeeElopements,
    weddingAlbum12ShippingFeeTotalElopements,
    setWeddingAlbum12ShippingFeeTotalElopements,
    weddingAlbum12ProfessionalDesignPriceElopements,
    weddingAlbum12ProfessionalDesignPriceTotalElopements,
    setWeddingAlbum12ProfessionalDesignPriceTotalElopements,
    // Premium package (Photo)
    premiumPhotoPackageSelectedElopements,
    // One Videographer
    oneVideographerSelectedElopements,
    setOneVideographerSelectedElopements,
    oneVideographerControlElopements,
    oneVideographerHourElopements,
    setOneVideographerHourElopements,
    oneVideographerPriceDefaultElopements,
    oneVideographerPriceElopements,
    setOneVideographerPriceElopements,
    oneVideographerPricePerHourElopements,
    // Premium package (Video)
    premiumVideoPackageSelectedElopements,
    setPremiumVideoPackagePriceElopements,
    premiumVideoPackagePriceDefaultElopements,
    premiumVideoPackagePriceDiscountElopements,
    setPremiumVideoPackagePopupTitleElopements,
    premiumVideoPackagePopupTitleDefaultElopements,
    premiumVideoPackagePopupTitleDiscountElopements,
    // Social media package (Sneak Peek)
    // Photo
    socialMediaPackagePhotoSelectedElopements,
    socialMediaPackagePhotoPriceElopements,
    // Video
    socialMediaPackageVideoSelectedElopements,
    socialMediaPackageVideoPriceElopements,
    // Photo & Video
    socialMediaPackagePhotoAndVideoSelectedElopements,
    setSocialMediaPackagePhotoAndVideoSelectedElopements,
    socialMediaPackagePhotoAndVideoPriceElopements,
    // Officiant
    officiantSelectedElopements,
    officiantPriceElopements,
    setOfficiantPriceElopements,
  } = packageConfiguratorElopementsState;
  //#endregion

  //#region One Photographer Elopements
  // handle selected state
  // TODO: accessibility state
  const handleOnePhotographerSelectedElopements = (ev) => {
    clearStoragePackageStateElopements();
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      setOnePhotographerSelectedElopements(!onePhotographerSelectedElopements);
      handleKeyPressed(ev.keyCode);
    }
  };

  // handle price based on hours
  useEffect(() => {
    if (onePhotographerControlElopements === 'decrease') {
      setOnePhotographerPriceElopements(
        onePhotographerPriceElopements - onePhotographerPricePerHourElopements
      );
    }
    if (onePhotographerControlElopements === 'increase') {
      setOnePhotographerPriceElopements(
        onePhotographerPriceElopements + onePhotographerPricePerHourElopements
      );
    }

    setOnePhotographerHourElopements(onePhotographerHourElopements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onePhotographerHourElopements, onePhotographerControlElopements]);

  const handleOnePhotographerPriceElopements = () => {
    if (onePhotographerHourElopements > 1) {
      let correctedOnePhotographerPrice = onePhotographerPriceDefaultElopements;

      for (let i = 1; i < onePhotographerHourElopements; i++) {
        correctedOnePhotographerPrice += onePhotographerPricePerHourElopements;
      }
      setOnePhotographerPriceElopements(correctedOnePhotographerPrice);
    }
  };

  //#region Wedding album elopements
  // handle plural for all wedding albums
  useEffect(() => {
    weddingAlbum10AmountElopements > 1
      ? setWeddingAlbum10InfoElopements('Albums')
      : setWeddingAlbum10InfoElopements('Album');

    weddingAlbum12AmountElopements > 1
      ? setWeddingAlbum12InfoElopements('Albums')
      : setWeddingAlbum12InfoElopements('Album');

    weddingAlbum10AmountFreeElopements > 1
      ? setWeddingAlbum10AmountFreeInfoElopements('Albums')
      : setWeddingAlbum10AmountFreeInfoElopements('Album');

    weddingAlbum12AmountFreeElopements > 1
      ? setWeddingAlbum12AmountFreeInfoElopements('Albums')
      : setWeddingAlbum12AmountFreeInfoElopements('Album');

    weddingAlbum10ProfessionalDesignAmountElopements > 1
      ? setWeddingAlbum10ProfessionalDesignInfoElopements('Designs')
      : setWeddingAlbum10ProfessionalDesignInfoElopements('Design');

    weddingAlbum12ProfessionalDesignAmountElopements > 1
      ? setWeddingAlbum12ProfessionalDesignInfoElopements('Designs')
      : setWeddingAlbum12ProfessionalDesignInfoElopements('Design');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum10AmountElopements,
    weddingAlbum10AmountFreeElopements,
    weddingAlbum12AmountElopements,
    weddingAlbum12AmountFreeElopements,
    weddingAlbum10ProfessionalDesignAmountElopements,
    weddingAlbum12ProfessionalDesignAmountElopements,
  ]);

  // handle pricing for 10x10 wedding album
  useEffect(() => {
    if (weddingAlbum10ControlElopements === 'decrease') {
      setWeddingAlbum10PriceElopements(
        weddingAlbum10PriceElopements -
          weddingAlbum10PriceDefaultElopements -
          weddingAlbum10ShippingFeeElopements
      );
      setWeddingAlbum10ShippingFeeTotalElopements(
        weddingAlbum10ShippingFeeTotalElopements -
          weddingAlbum10ShippingFeeElopements
      );
    }
    if (weddingAlbum10ControlElopements === 'increase') {
      setWeddingAlbum10PriceElopements(
        weddingAlbum10PriceElopements +
          weddingAlbum10PriceDefaultElopements +
          weddingAlbum10ShippingFeeElopements
      );
      setWeddingAlbum10ShippingFeeTotalElopements(
        weddingAlbum10ShippingFeeTotalElopements +
          weddingAlbum10ShippingFeeElopements
      );
    }
    if (weddingAlbum10AmountElopements === 1) {
      setWeddingAlbum10PriceElopements(
        weddingAlbum10PriceDefaultElopements +
          weddingAlbum10ShippingFeeElopements
      );
      setWeddingAlbum10ShippingFeeTotalElopements(
        weddingAlbum10ShippingFeeElopements
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weddingAlbum10AmountElopements]);

  // re calculate price on page refresh (elopements and checkout page)
  const handlePriceTotalWeddingAlbum10Elopements = (w10a) => {
    if (weddingAlbum10AmountElopements === 1) {
      setWeddingAlbum10PriceElopements(
        Number(
          weddingAlbum10PriceDefaultElopements +
            weddingAlbum10ShippingFeeElopements
        )
      );
    }

    let correctedWeddingAlbum10Price = Number(
      weddingAlbum10PriceDefaultElopements + weddingAlbum10ShippingFeeElopements
    );

    let correctedShippingFee = Number(weddingAlbum10ShippingFeeElopements);

    let correctedWeddingAlbum10ProfessionalDesignPrice = 0;

    for (let i = 1; i < w10a; i++) {
      correctedWeddingAlbum10Price += weddingAlbum10PriceDefaultElopements;
      correctedWeddingAlbum10Price += weddingAlbum10ShippingFeeElopements;

      correctedShippingFee += weddingAlbum10ShippingFeeElopements;

      correctedWeddingAlbum10ProfessionalDesignPrice +=
        weddingAlbum10ProfessionalDesignPriceElopements;
    }

    setWeddingAlbum10PriceElopements(Number(correctedWeddingAlbum10Price));
    setWeddingAlbum10ShippingFeeTotalElopements(Number(correctedShippingFee));

    setWeddingAlbum10ProfessionalDesignPriceTotalElopements(
      Number(correctedWeddingAlbum10ProfessionalDesignPrice)
    );
  };

  // handle price total for 10x10 wedding album
  // deps on professional design
  useEffect(() => {
    setWeddingAlbum10PriceTotalElopements(weddingAlbum10PriceElopements);
    if (weddingAlbum10ProfessionalDesignSelectedElopements) {
      setWeddingAlbum10PriceTotalElopements(
        weddingAlbum10PriceElopements +
          weddingAlbum10ProfessionalDesignPriceTotalElopements
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum10PriceElopements,
    weddingAlbum10ProfessionalDesignSelectedElopements,
    weddingAlbum10ProfessionalDesignPriceTotalElopements,
  ]);

  // handle pricing for 12x12 wedding album
  useEffect(() => {
    if (weddingAlbum12ControlElopements === 'decrease') {
      setWeddingAlbum12PriceElopements(
        weddingAlbum12PriceElopements -
          weddingAlbum12PriceDefaultElopements -
          weddingAlbum12ShippingFeeElopements
      );
      setWeddingAlbum12ShippingFeeTotalElopements(
        weddingAlbum12ShippingFeeTotalElopements -
          weddingAlbum12ShippingFeeElopements
      );
    }
    if (weddingAlbum12ControlElopements === 'increase') {
      setWeddingAlbum12PriceElopements(
        weddingAlbum12PriceElopements +
          weddingAlbum12PriceDefaultElopements +
          weddingAlbum12ShippingFeeElopements
      );
      setWeddingAlbum12ShippingFeeTotalElopements(
        weddingAlbum12ShippingFeeTotalElopements +
          weddingAlbum12ShippingFeeElopements
      );
    }
    if (weddingAlbum12AmountElopements === 1) {
      setWeddingAlbum12PriceElopements(
        weddingAlbum12PriceDefaultElopements +
          weddingAlbum12ShippingFeeElopements
      );
      setWeddingAlbum12ShippingFeeTotalElopements(
        weddingAlbum12ShippingFeeElopements
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [weddingAlbum12AmountElopements]);

  // re calculate price on page refresh (elopements and checkout page)
  const handlePriceTotalWeddingAlbum12Elopements = (w12a) => {
    if (weddingAlbum12AmountElopements === 1) {
      setWeddingAlbum12PriceElopements(
        Number(
          weddingAlbum12PriceDefaultElopements +
            weddingAlbum12ShippingFeeElopements
        )
      );
    }

    let correctedWeddingAlbum12Price = Number(
      weddingAlbum12PriceDefaultElopements + weddingAlbum12ShippingFeeElopements
    );

    let correctedShippingFee = Number(weddingAlbum12ShippingFeeElopements);

    let correctedWeddingAlbum12ProfessionalDesignPrice = 0;

    for (let i = 1; i < w12a; i++) {
      correctedWeddingAlbum12Price += weddingAlbum12PriceDefaultElopements;
      correctedWeddingAlbum12Price += weddingAlbum12ShippingFeeElopements;

      correctedShippingFee += weddingAlbum12ShippingFeeElopements;

      correctedWeddingAlbum12ProfessionalDesignPrice +=
        weddingAlbum12ProfessionalDesignPriceElopements;
    }

    setWeddingAlbum12PriceElopements(Number(correctedWeddingAlbum12Price));
    setWeddingAlbum12ShippingFeeTotalElopements(Number(correctedShippingFee));

    setWeddingAlbum12ProfessionalDesignPriceTotalElopements(
      Number(correctedWeddingAlbum12ProfessionalDesignPrice)
    );
  };

  // handle price total for 12x12 wedding album
  // deps on professional design
  useEffect(() => {
    setWeddingAlbum12PriceTotalElopements(weddingAlbum12PriceElopements);
    if (weddingAlbum12ProfessionalDesignSelectedElopements) {
      setWeddingAlbum12PriceTotalElopements(
        weddingAlbum12PriceElopements +
          weddingAlbum12ProfessionalDesignPriceTotalElopements
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum12PriceElopements,
    weddingAlbum12ProfessionalDesignSelectedElopements,
    weddingAlbum12ProfessionalDesignPriceTotalElopements,
  ]);

  // handle 'professional design' amount and price
  // deps on 'professional design' selected state, album amount
  useEffect(() => {
    // if (!uriChangeElopements) {
    if (weddingAlbum10ProfessionalDesignSelectedElopements) {
      setWeddingAlbum10ProfessionalDesignPriceTotalElopements(
        weddingAlbum10ProfessionalDesignPriceElopements *
          weddingAlbum10ProfessionalDesignAmountElopements
      );
      // setWeddingAlbum10ProfessionalDesignAmountElopements(
      //   weddingAlbum10AmountElopements
      // );
    }
    if (!weddingAlbum10ProfessionalDesignSelectedElopements) {
      setWeddingAlbum10ProfessionalDesignPriceTotalElopements(
        weddingAlbum10ProfessionalDesignPriceElopements *
          weddingAlbum10ProfessionalDesignAmountElopements
      );
      setWeddingAlbum10ProfessionalDesignAmountElopements(
        weddingAlbum10AmountElopements
      );
      setWeddingAlbum10AmountFreeElopements(0);
    }

    if (weddingAlbum12ProfessionalDesignSelectedElopements) {
      setWeddingAlbum12ProfessionalDesignPriceTotalElopements(
        weddingAlbum12ProfessionalDesignPriceElopements *
          weddingAlbum12AmountElopements
      );
      // setWeddingAlbum12ProfessionalDesignAmountElopements(
      //   weddingAlbum12AmountElopements
      // );
    }
    if (!weddingAlbum12ProfessionalDesignSelectedElopements) {
      setWeddingAlbum12ProfessionalDesignPriceTotalElopements(
        weddingAlbum12ProfessionalDesignPriceElopements *
          weddingAlbum12AmountElopements
      );
      setWeddingAlbum12ProfessionalDesignAmountElopements(
        weddingAlbum12AmountElopements
      );
      setWeddingAlbum12AmountFreeElopements(0);
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum10AmountElopements,
    weddingAlbum10ProfessionalDesignAmountElopements,
    weddingAlbum12AmountElopements,
    weddingAlbum12ProfessionalDesignAmountElopements,
    weddingAlbum10ProfessionalDesignSelectedElopements,
    weddingAlbum12ProfessionalDesignSelectedElopements,
  ]);

  // handle professional design amount and free DIY amount
  useEffect(() => {
    // if (!uriChangeElopements) {
    if (
      weddingAlbum10ProfessionalDesignAmountElopements >
      weddingAlbum10AmountElopements
    ) {
      setWeddingAlbum10ProfessionalDesignAmountElopements(
        weddingAlbum10AmountElopements
      );
    }
    if (
      weddingAlbum12ProfessionalDesignAmountElopements >
      weddingAlbum12AmountElopements
    ) {
      setWeddingAlbum12ProfessionalDesignAmountElopements(
        weddingAlbum12AmountElopements
      );
    }
    if (weddingAlbum10ProfessionalDesignAmountElopements > 0) {
      setWeddingAlbum10AmountFreeElopements(
        weddingAlbum10AmountElopements -
          weddingAlbum10ProfessionalDesignAmountElopements
      );
    }
    if (weddingAlbum12ProfessionalDesignAmountElopements > 0) {
      setWeddingAlbum12AmountFreeElopements(
        weddingAlbum12AmountElopements -
          weddingAlbum12ProfessionalDesignAmountElopements
      );
    }
    if (weddingAlbum10ProfessionalDesignAmountElopements === 0) {
      setWeddingAlbum10ProfessionalDesignSelectedElopements(false);
      setWeddingAlbum10AmountFreeElopements(0);
    }
    if (weddingAlbum12ProfessionalDesignAmountElopements === 0) {
      setWeddingAlbum12ProfessionalDesignSelectedElopements(false);
      setWeddingAlbum12AmountFreeElopements(0);
    }
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum10AmountElopements,
    weddingAlbum12AmountElopements,
    weddingAlbum10AmountFreeElopements,
    weddingAlbum12AmountFreeElopements,
    weddingAlbum10ProfessionalDesignAmountElopements,
    weddingAlbum12ProfessionalDesignAmountElopements,
  ]);

  // handle wedding album price total and
  // 'professional design' amount
  // deps on wedding album amount
  useEffect(() => {
    if (!uriChangeElopements) {
      setWeddingAlbum10ProfessionalDesignPriceTotalElopements(
        weddingAlbum10ProfessionalDesignPriceElopements *
          weddingAlbum10ProfessionalDesignAmountElopements
      );

      if (weddingAlbum10ProfessionalDesignAmountElopements === 0) {
        setWeddingAlbum10ProfessionalDesignSelectedElopements(false);
        setWeddingAlbum10AmountFreeElopements(0);
      }

      setWeddingAlbum12ProfessionalDesignPriceTotalElopements(
        weddingAlbum12ProfessionalDesignPriceElopements *
          weddingAlbum12ProfessionalDesignAmountElopements
      );

      if (weddingAlbum12ProfessionalDesignAmountElopements === 0) {
        setWeddingAlbum12ProfessionalDesignSelectedElopements(false);
        setWeddingAlbum12AmountFreeElopements(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum10ProfessionalDesignPriceTotalElopements,
    weddingAlbum12ProfessionalDesignPriceTotalElopements,
    weddingAlbum10ProfessionalDesignAmountElopements,
    weddingAlbum12ProfessionalDesignAmountElopements,
  ]);

  // Videography state
  // handle selected state
  // TODO: accessiblity state
  const handleOneVideographerSelectedElopements = (ev) => {
    clearStoragePackageStateElopements();
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      setOneVideographerSelectedElopements(!oneVideographerSelectedElopements);
    }
    handleKeyPressed(ev.keyCode);
  };

  // handle price based on hours
  const handleOneVideographerPriceElopements = () => {
    if (oneVideographerHourElopements > 1) {
      let correctedOneVideographerPrice = oneVideographerPriceDefaultElopements;

      for (let i = 1; i < oneVideographerHourElopements; i++) {
        correctedOneVideographerPrice += oneVideographerPricePerHourElopements;
      }

      setOneVideographerPriceElopements(correctedOneVideographerPrice);
    }
  };

  useEffect(() => {
    if (oneVideographerControlElopements === 'decrease') {
      setOneVideographerPriceElopements(
        oneVideographerPriceElopements - oneVideographerPricePerHourElopements
      );
    }

    if (oneVideographerControlElopements === 'increase') {
      setOneVideographerPriceElopements(
        oneVideographerPriceElopements + oneVideographerPricePerHourElopements
      );
    }
    setOneVideographerHourElopements(oneVideographerHourElopements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [oneVideographerHourElopements, oneVideographerControlElopements]);

  //#region Officiant state elopements
  // handle officiant pricing
  const handleOfficiantPriceElopements = () => {
    if (officiantSelectedElopements) {
      setOfficiantPriceElopements(Number(officiantElopements.price));
    }
  };
  //#endregion

  //#region Premium Packages Elopements
  // Setting title in PackageModal
  const [isPremiumPackageAddedElopements, setIsPremiumPackageAddedElopements] =
    useState(false);

  // set title to either title or discountTitle
  // and adjust pricing of video
  useEffect(() => {
    if (!premiumPhotoPackageSelectedElopements) {
      setIsPremiumPackageAddedElopements(false);
      setPremiumVideoPackagePopupTitleElopements(
        premiumVideoPackagePopupTitleDefaultElopements
      );
      setPremiumVideoPackagePriceElopements(
        premiumVideoPackagePriceDefaultElopements
      );
    }
    if (premiumPhotoPackageSelectedElopements) {
      setIsPremiumPackageAddedElopements(true);
      setPremiumVideoPackagePopupTitleElopements(
        premiumVideoPackagePopupTitleDiscountElopements
      );
      setPremiumVideoPackagePriceElopements(
        premiumVideoPackagePriceDiscountElopements
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [premiumPhotoPackageSelectedElopements]);

  //#region Storage elopements
  // handle storage package
  const [storageElopementsInfo] = useState(storageElopements.info);
  const [storageElopementsPackagePrice, setStorageElopementsPackagePrice] =
    useState(0);

  // photo storage package
  const [storageElopementsPhotoPackageInfo] = useState(
    storageElopements.photo.info
  );
  const [storageElopementsPhotoPackageOption1PopupText] = useState(
    storageElopements.photo.options.option1PopupText
  );
  const [storageElopementsPhotoPackageOption1SidebarText] = useState(
    storageElopements.photo.options.option1SidebarText
  );
  const [storageElopementsPhotoPackageOption1Price] = useState(
    storageElopements.photo.options.option1Price
  );
  const [storageElopementsPhotoPackageOption2PopupText] = useState(
    storageElopements.photo.options.option2PopupText
  );
  const [storageElopementsPhotoPackageOption2SidebarText] = useState(
    storageElopements.photo.options.option2SidebarText
  );
  const [storageElopementsPhotoPackageOption2Price] = useState(
    storageElopements.photo.options.option2Price
  );
  const [storageElopementsPhotoPackageOption3PopupText] = useState(
    storageElopements.photo.options.option3PopupText
  );
  const [storageElopementsPhotoPackageOption3SidebarText] = useState(
    storageElopements.photo.options.option3SidebarText
  );
  const [storageElopementsPhotoPackageOption3Price] = useState(
    storageElopements.photo.options.option3Price
  );

  // video storage package
  const [storageElopementsVideoPackageInfo] = useState(
    storageElopements.video.info
  );
  const [storageElopementsVideoPackageOption1PopupText] = useState(
    storageElopements.video.options.option1PopupText
  );
  const [storageElopementsVideoPackageOption1SidebarText] = useState(
    storageElopements.video.options.option1SidebarText
  );
  const [storageElopementsVideoPackageOption1Price] = useState(
    storageElopements.video.options.option1Price
  );
  const [storageElopementsVideoPackageOption2PopupText] = useState(
    storageElopements.video.options.option2PopupText
  );
  const [storageElopementsVideoPackageOption2SidebarText] = useState(
    storageElopements.video.options.option2SidebarText
  );
  const [storageElopementsVideoPackageOption2Price] = useState(
    storageElopements.video.options.option2Price
  );
  const [storageElopementsVideoPackageOption3PopupText] = useState(
    storageElopements.video.options.option3PopupText
  );
  const [storageElopementsVideoPackageOption3SidebarText] = useState(
    storageElopements.video.options.option3SidebarText
  );
  const [storageElopementsVideoPackageOption3Price] = useState(
    storageElopements.video.options.option3Price
  );

  // photo and video storage package
  const [storageElopementsPhotoAndVideoPackageInfo] = useState(
    storageElopements.photoVideo.info
  );
  const [storageElopementsPhotoAndVideoPackageOption1PopupText] = useState(
    storageElopements.photoVideo.options.option1PopupText
  );
  const [storageElopementsPhotoAndVideoPackageOption1SidebarText] = useState(
    storageElopements.photoVideo.options.option1SidebarText
  );
  const [storageElopementsPhotoAndVideoPackageOption1Price] = useState(
    storageElopements.photoVideo.options.option1Price
  );
  const [storageElopementsPhotoAndVideoPackageOption2PopupText] = useState(
    storageElopements.photoVideo.options.option2PopupText
  );
  const [storageElopementsPhotoAndVideoPackageOption2SidebarText] = useState(
    storageElopements.photoVideo.options.option2SidebarText
  );
  const [storageElopementsPhotoAndVideoPackageOption2Price] = useState(
    storageElopements.photoVideo.options.option2Price
  );
  const [storageElopementsPhotoAndVideoPackageOption3PopupText] = useState(
    storageElopements.photoVideo.options.option3PopupText
  );
  const [storageElopementsPhotoAndVideoPackageOption3SidebarText] = useState(
    storageElopements.photoVideo.options.option3SidebarText
  );
  const [storageElopementsPhotoAndVideoPackageOption3Price] = useState(
    storageElopements.photoVideo.options.option3Price
  );

  // handle storage state
  const [
    storagePackageSelectedElopements,
    setStoragePackageSelectedElopements,
  ] = useState(false);

  // TODO: accessibility state
  const handleStoragePackageSelectedElopements = () => {
    setStoragePackageSelectedElopements(!storagePackageSelectedElopements);
  };

  // clear storage package state
  useEffect(() => {
    if (storagePackageSelectedElopements === false) {
      clearStoragePackageStateElopements();
    }
  }, [
    storagePackageSelectedElopements,
    onePhotographerSelectedElopements,
    oneVideographerSelectedElopements,
  ]);

  // clear storage package state on package change
  // examp. if storage is selected with one photographer
  // then when one videographer is added clear storage package state
  const clearStoragePackageStateElopements = () => {
    setCheckedStorageValueElopements(null);
    setCheckedStorageValueModalElopements(null);
    setToggleCheckoutBtnInfo(false);
    setStoragePackageSelectedElopements(false);
    setStorageElopementsPackagePrice(0);
  };

  const [storagePackageTypeElopements, setStoragePackageTypeElopements] =
    useState('');

  const handleStoragePackageElopements = (type) => {
    setStoragePackageTypeElopements(type);
  };
  //#endregion

  //#region Storage in package modal
  // state is separate for weddings and elopements, handleStoragePackage()
  const [
    checkedStorageValueModalElopements,
    setCheckedStorageValueModalElopements,
  ] = useState(null);
  const [checkedStorageValueElopements, setCheckedStorageValueElopements] =
    useState(null);

  // handle storage package inputs
  const handleStorageElopementsPackageChange = (price, ev) => {
    handlePreventDefault(ev);
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      if (checkedStorageValueElopements === ev.target.value) {
        setCheckedStorageValueElopements(null);
        setCheckedStorageValueModalElopements(null);
        setToggleCheckoutBtnInfo(false);
        setStoragePackageSelectedElopements(false);
        setStorageElopementsPackagePrice(0);
      } else {
        setCheckedStorageValueElopements(ev.target.value);
        setCheckedStorageValueModalElopements(ev.target.value);
        setToggleCheckoutBtnInfo(true);
        setStoragePackageSelectedElopements(true);
        setStorageElopementsPackagePrice(Number(price));
      }
      handleKeyPressed(ev.keyCode);
    }
  };

  // sync inputs in modal and in sidebar with state
  useEffect(() => {
    if (checkedStorageValueModalElopements !== null) {
      setCheckedStorageValueElopements(checkedStorageValueModalElopements);
    }
  }, [
    checkedStorageValueModalElopements,
    checkedStorageValueElopements,
    storagePackageTypeElopements,
  ]);

  const handleStoragePriceElopements = () => {
    if (storagePackageSelectedElopements) {
      if (storagePackageTypeElopements === 'photo') {
        if (checkedStorageValueElopements === '1') {
          setStorageElopementsPackagePrice(
            storageElopementsPhotoPackageOption1Price
          );
        }
        if (checkedStorageValueElopements === '2') {
          setStorageElopementsPackagePrice(
            storageElopementsPhotoPackageOption2Price
          );
        }
        if (checkedStorageValueElopements === '3') {
          setStorageElopementsPackagePrice(
            storageElopementsPhotoPackageOption3Price
          );
        }
      }
      if (storagePackageTypeElopements === 'video') {
        if (checkedStorageValueElopements === '1') {
          setStorageElopementsPackagePrice(
            storageElopementsVideoPackageOption1Price
          );
        }
        if (checkedStorageValueElopements === '2') {
          setStorageElopementsPackagePrice(
            storageElopementsVideoPackageOption2Price
          );
        }
        if (checkedStorageValueElopements === '3') {
          setStorageElopementsPackagePrice(
            storageElopementsVideoPackageOption3Price
          );
        }
      }
      if (storagePackageTypeElopements === 'photoVideo') {
        if (checkedStorageValueElopements === '1') {
          setStorageElopementsPackagePrice(
            storageElopementsPhotoAndVideoPackageOption1Price
          );
        }
        if (checkedStorageValueElopements === '2') {
          setStorageElopementsPackagePrice(
            storageElopementsPhotoAndVideoPackageOption2Price
          );
        }
        if (checkedStorageValueElopements === '3') {
          setStorageElopementsPackagePrice(
            storageElopementsPhotoAndVideoPackageOption3Price
          );
        }
      }
    }
  };

  //#region Configurator Storage packages
  const [photoDownloadLinkSelected, setPhotoDownloadLinkSelected] =
    useState(false);
  const [photoDownloadLinkPrice] = useState(
    Number(configuratorStorage.photoDownloadLinkPrice)
  );
  const [videoDownloadLinkSelected, setVideoDownloadLinkSelected] =
    useState(false);
  const [videoDownloadLinkPrice] = useState(
    Number(configuratorStorage.videoDownloadLinkPrice)
  );
  const [photoVideoDownloadLinkSelected, setPhotoVideoDownloadLinkSelected] =
    useState(false);
  const [photoVideoDownloadLinkPrice] = useState(
    Number(configuratorStorage.photoVideoDownloadLinkPrice)
  );
  const [packagesStorageSelected, setPackagesStorageSelected] = useState(false);
  const [packagesStoragePrice, setPackagesStoragePrice] = useState(0);
  const [ccFeeStorage, setCCFeeStorage] = useState(0);

  const handlePackagesStorage = (option, ev) => {
    handlePreventDefault(ev);
    if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
      if (option === 1) {
        setPhotoDownloadLinkSelected(!photoDownloadLinkSelected);
        setVideoDownloadLinkSelected(false);
        setPhotoVideoDownloadLinkSelected(false);
        setPackagesStoragePrice(photoDownloadLinkPrice);
        setGrandTotalPriceStorage(photoDownloadLinkPrice);
      }
      if (option === 2) {
        setPhotoDownloadLinkSelected(false);
        setVideoDownloadLinkSelected(!videoDownloadLinkSelected);
        setPhotoVideoDownloadLinkSelected(false);
        setPackagesStoragePrice(videoDownloadLinkPrice);
        setGrandTotalPriceStorage(videoDownloadLinkPrice);
      }
      if (option === 3) {
        setPhotoDownloadLinkSelected(false);
        setVideoDownloadLinkSelected(false);
        setPhotoVideoDownloadLinkSelected(!photoVideoDownloadLinkSelected);
        setPackagesStoragePrice(photoVideoDownloadLinkPrice);
        setGrandTotalPriceStorage(photoVideoDownloadLinkPrice);
      }
      handleKeyPressed(ev.keyCode);
    }
  };

  // TODO: accessibility state
  const handlePackagesStorageSelected = () => {
    setPackagesStorageSelected(!packagesStorageSelected);
    setPhotoDownloadLinkSelected(false);
    setVideoDownloadLinkSelected(false);
    setPhotoVideoDownloadLinkSelected(false);
  };

  const handlePackagesStoragePricing = () => {
    if (photoDownloadLinkSelected) {
      setPackagesStoragePrice(photoDownloadLinkPrice);
      setGrandTotalPriceStorage(photoDownloadLinkPrice);
    }
    if (videoDownloadLinkSelected) {
      setPackagesStoragePrice(videoDownloadLinkPrice);
      setGrandTotalPriceStorage(videoDownloadLinkPrice);
    }
    if (photoVideoDownloadLinkSelected) {
      setPackagesStoragePrice(photoVideoDownloadLinkPrice);
      setGrandTotalPriceStorage(photoVideoDownloadLinkPrice);
    }
  };

  useEffect(() => {
    if (
      photoDownloadLinkSelected ||
      videoDownloadLinkSelected ||
      photoVideoDownloadLinkSelected
    ) {
      setPackagesStorageSelected(true);
      handlePackagesStoragePricing();
    } else {
      setPackagesStorageSelected(false);
      setPackagesStoragePrice(0);
      setGrandTotalPriceStorage(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    photoDownloadLinkSelected,
    videoDownloadLinkSelected,
    photoVideoDownloadLinkSelected,
  ]);

  //#region Defaults for, Weddings, Elopements and Storage
  // isMobile check
  const [isMobile, setIsMobile] = useState(false);
  // show package weddings
  const [showPackage, setShowPackage] = useState(false);
  // show package elopements
  const [showPackageElopements, setShowPackageElopements] = useState(false);
  // show package storage
  const [showPackageStorage, setShowPackageStorage] = useState(false);

  // TODO: accessibility state
  const togglePackage = () => {
    setShowPackage(!showPackage);
  };

  // TODO: accessibility state
  const togglePackageElopements = () => {
    setShowPackageElopements(!showPackageElopements);
  };

  // TODO: accessibility state
  const togglePackageStorage = () => {
    setShowPackageStorage(!showPackageStorage);
  };

  // add overflow:hidden to body on modal open
  // to remove scroll on background
  useEffect(() => {
    if (showPackage || showPackageElopements) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style = '';
    }
  }, [showPackage, showPackageElopements]);

  // add/remove isMobile css class
  useEffect(() => {
    const bodyEl = document.body;

    const resizeBodyObserver = new ResizeObserver(() => {
      if (bodyEl.getBoundingClientRect().width < 1400) {
        // if (bodyEl.getBoundingClientRect().width < 1280) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    });

    resizeBodyObserver.observe(bodyEl);

    return () => {
      resizeBodyObserver.unobserve(bodyEl);
    };
  }, [isMobile]);

  // hide mobile sidebar on resize
  useEffect(() => {
    if (!isMobile) {
      setShowPackage(false);
      setShowPackageElopements(false);
    }
  }, [isMobile]);
  //#endregion

  // calculate deposit weddings
  const [depositPrice, setDepositPrice] = useState(0);

  // package price
  const [packagePrice, setPackagePrice] = useState(0);
  const [packagePriceElopements, setPackagePriceElopements] = useState(0);

  // Tax Weddings, Elopements
  const [taxRate] = useState(0.08875); // 8.875%
  const [tax, setTax] = useState(0);
  const [taxElopements, setTaxElopements] = useState(0);

  useEffect(() => {
    setDepositPrice(Number((packagePrice + tax) * 0.2).toFixed(2));
    // setDepositPrice(Number(grandTotalPrice * 0.2).toFixed(2));
  }, [grandTotalPrice, packagePrice, tax]);

  // calculate deposit elopements
  const [depositPriceElopements, setDepositPriceElopements] = useState(0);

  useEffect(() => {
    setDepositPriceElopements(
      Number((packagePriceElopements + taxElopements) * 0.2).toFixed(2)
      // Number(grandTotalPriceElopements * 0.2).toFixed(2)
    );
    console.log('packace price elopements:', packagePriceElopements);
    console.log('tax elopements:', taxElopements);
    console.log(
      'DEPOSIT ELOPEMENTS:',
      Number((packagePriceElopements + taxElopements) * 0.2).toFixed(2)
    );
  }, [grandTotalPriceElopements, packagePriceElopements, taxElopements]);

  //#region Calculate grand total packages storage
  const calculateGrandTotalStorage = () => {
    if (grandTotalPriceStorage === 0) {
      setShowPackageStorage(false);
    }
    // let totalPriceStorage;

    // const packagePriceElementsStorage = document.querySelectorAll(
    //   '.item-price-storage-selected'
    // );

    // const packagePriceMappedStorage = Array.from(
    //   packagePriceElementsStorage
    // ).map((el) => {
    //   return Number(el.textContent);
    // });

    // // add all prices
    // totalPriceStorage = packagePriceMappedStorage.reduce((a, b) => a + b, 0);
    // setGrandTotalPriceStorage(totalPriceStorage);
  };

  // grand total price packages storage
  useEffect(() => {
    calculateGrandTotalStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    photoDownloadLinkSelected,
    photoDownloadLinkPrice,
    videoDownloadLinkSelected,
    videoDownloadLinkPrice,
    photoVideoDownloadLinkSelected,
    photoVideoDownloadLinkPrice,
    packagesStoragePrice,
  ]);
  //#endregion

  // hide sidebar when grand total is 0
  // redirect to package configurator from checkout page
  useEffect(() => {
    if (grandTotalPrice === 0) {
      setShowPackage(false);
    }

    // if grand total is 0, on checkout page
    // redirect to package configurator
    if (grandTotalPrice === 0 && document.body.className === 'checkout') {
      const currentPage = document.body.className;

      if (
        packageConfiguratorWeddings &&
        !packageConfiguratorElopements &&
        currentPage !== 'wedding-packages' &&
        onePhotographerSelected === false &&
        engagementOneHourSelected === false &&
        engagementTwoHoursSelected === false &&
        oneVideographerSelected === false &&
        photoBoothBasicSelected === false &&
        photoBoothAllInclusiveSelected === false
      ) {
        console.log('TODO: CLEAR HERE!');
        // navigate('/wedding-packages/');
        // window.location.replace('/wedding-packages/');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grandTotalPrice]);

  // hide sidebar when grand total is 0
  // redirect to package configurator elopements from checkout page
  useEffect(() => {
    if (grandTotalPriceElopements === 0) {
      setShowPackageElopements(false);
    }

    // if grand total is 0, on checkout page
    // redirect to package configurator
    if (
      grandTotalPriceElopements === 0 &&
      document.body.className === 'checkout'
    ) {
      const currentPage = document.body.className;

      if (
        !packageConfiguratorWeddings &&
        packageConfiguratorElopements &&
        currentPage !== 'elopement' &&
        onePhotographerSelectedElopements === false &&
        oneVideographerSelectedElopements === false &&
        officiantSelectedElopements === false
      ) {
        console.log('TODO: CLEAR HERE!');
        return;
        navigate('/wedding/elopement/');
      }
    } else {
      calculateGrandTotalElopements();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grandTotalPriceElopements]);

  // redirect to package configurator storage from checkout page
  useEffect(() => {
    if (grandTotalPriceStorage === 0) {
      setShowPackageStorage(false);
    }
    // if grand total is 0, on checkout page
    // redirect to package configurator
    if (
      grandTotalPriceStorage === 0 &&
      document.body.className === 'checkout'
    ) {
      const currentPage = document.body.className;

      if (
        !packageConfiguratorWeddings &&
        !packageConfiguratorElopements &&
        packageConfiguratorStorage &&
        currentPage !== 'storage-packages' &&
        photoDownloadLinkSelected === false &&
        videoDownloadLinkSelected === false &&
        photoVideoDownloadLinkSelected === false
      ) {
        console.log('TODO: CLEAR HERE!');
        return;
        navigate('/storage-packages/');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grandTotalPriceStorage]);

  //#region TODO: Handle discount
  const [discountApplied, setDiscountApplied] = useState(false);
  const [discountAmount, setDiscountAmount] = useState(0);

  useEffect(() => {
    if (
      onePhotographerSelected &&
      (photoBoothBasicSelected || photoBoothAllInclusiveSelected)
    ) {
      setDiscountApplied(true);
      setDiscountAmount(() => {
        // TODO: hadrcode it for now, beacuse its the only discount
        // maybe add it as an option in wordpress
        return '100';
      });
    } else {
      setDiscountApplied(false);
      setDiscountAmount(0);
    }
  }, [
    onePhotographerSelected,
    photoBoothBasicSelected,
    photoBoothAllInclusiveSelected,
  ]);

  // discount value
  const [discountValue, setDiscountValue] = useState(0);

  useEffect(() => {
    if (discountAmount) {
      setDiscountValue(-discountAmount);
    } else {
      setDiscountValue(0);
    }
  }, [discountAmount, grandTotalPrice]);
  //#endregion

  //#region Tax Weddings
  const [weddingAlbum10Tax, setWeddingAlbum10Tax] = useState(0);
  const [weddingAlbum12Tax, setWeddingAlbum12Tax] = useState(0);
  const [photoBoothBasicTax, setPhotoBoothBasicTax] = useState(0);
  const [photoBoothAllInclusiveTax, setPhotoBoothAllInclusiveTax] = useState(0);

  // calculate tax for package options that have tax
  useEffect(() => {
    if (weddingAlbum10Selected) {
      setWeddingAlbum10Tax(Number(calculateWeddingAlbum10Tax().toFixed(2)));
    }
    if (!weddingAlbum10Selected) {
      setWeddingAlbum10Tax(0);
    }

    if (weddingAlbum12Selected) {
      setWeddingAlbum12Tax(Number(calculateWeddingAlbum12Tax().toFixed(2)));
    }
    if (!weddingAlbum12Selected) {
      setWeddingAlbum12Tax(0);
    }

    if (photoBoothBasicSelected) {
      setPhotoBoothBasicTax(Number(calculatePhotoBoothBasicTax().toFixed(2)));
    }
    if (!photoBoothBasicSelected) {
      setPhotoBoothBasicTax(0);
    }

    if (photoBoothAllInclusiveSelected) {
      setPhotoBoothAllInclusiveTax(
        Number(calculatePhotoBoothAllInclusiveTax().toFixed(2))
      );
    }
    if (!photoBoothAllInclusiveSelected) {
      setPhotoBoothAllInclusiveTax(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum10Selected,
    weddingAlbum12Selected,
    photoBoothBasicSelected,
    photoBoothAllInclusiveSelected,
    weddingAlbum10PriceTotal,
    weddingAlbum12PriceTotal,
    photoBoothPackagePrice,
    grandTotalPrice,
  ]);

  // set cumulative tax
  useEffect(() => {
    if (
      weddingAlbum10Selected ||
      weddingAlbum12Selected ||
      photoBoothBasicSelected ||
      photoBoothAllInclusiveSelected
    ) {
      setTax(
        Number(
          (
            weddingAlbum10Tax +
            weddingAlbum12Tax +
            photoBoothBasicTax +
            photoBoothAllInclusiveTax
          ).toFixed(2)
        )
      );
    } else {
      setTax(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum10Selected,
    weddingAlbum12Selected,
    photoBoothBasicSelected,
    photoBoothAllInclusiveSelected,
    weddingAlbum10Tax,
    weddingAlbum12Tax,
    photoBoothBasicTax,
    photoBoothAllInclusiveTax,
  ]);

  const calculateWeddingAlbum10Tax = () => {
    return Number((weddingAlbum10Price * taxRate).toFixed(2));
  };

  const calculateWeddingAlbum12Tax = () => {
    return Number((weddingAlbum12Price * taxRate).toFixed(2));
  };

  const calculatePhotoBoothBasicTax = () => {
    return Number((photoBoothBasicPackagePrice * taxRate).toFixed(2));
  };

  const calculatePhotoBoothAllInclusiveTax = () => {
    return Number((photoBoothAllInclusivePrice * taxRate).toFixed(2));
  };
  //#endregion

  //#region Tax elopements
  const [weddingAlbum10TaxElopements, setWeddingAlbum10TaxElopements] =
    useState(0);
  const [weddingAlbum12TaxElopements, setWeddingAlbum12TaxElopements] =
    useState(0);

  // calculate tax for package options that have tax
  useEffect(() => {
    // deselect wedding albums if one photographer selected is false
    // to prevent tax calculation bug
    if (!onePhotographerSelectedElopements) {
      setWeddingAlbum10SelectedElopements(false);
      setWeddingAlbum12SelectedElopements(false);
    }
    // console.log('photoBoothPackagePrice', photoBoothPackagePrice);
    if (weddingAlbum10SelectedElopements) {
      setWeddingAlbum10TaxElopements(
        Number(calculateWeddingAlbum10TaxElopements().toFixed(2))
      );
    }
    if (!weddingAlbum10SelectedElopements) {
      setWeddingAlbum10TaxElopements(0);
    }

    if (weddingAlbum12SelectedElopements) {
      setWeddingAlbum12TaxElopements(
        Number(calculateWeddingAlbum12TaxElopements().toFixed(2))
      );
    }
    if (!weddingAlbum12SelectedElopements) {
      setWeddingAlbum12TaxElopements(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    onePhotographerSelectedElopements,
    weddingAlbum10SelectedElopements,
    weddingAlbum12SelectedElopements,
    weddingAlbum10PriceTotalElopements,
    weddingAlbum12PriceTotalElopements,
    grandTotalPriceElopements,
  ]);

  // set cumulative tax
  useEffect(() => {
    if (weddingAlbum10SelectedElopements || weddingAlbum12SelectedElopements) {
      setTaxElopements(
        Number(
          (weddingAlbum10TaxElopements + weddingAlbum12TaxElopements).toFixed(2)
        )
      );
    } else {
      setTaxElopements(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    weddingAlbum10SelectedElopements,
    weddingAlbum12SelectedElopements,
    weddingAlbum10TaxElopements,
    weddingAlbum12TaxElopements,
  ]);

  const calculateWeddingAlbum10TaxElopements = () => {
    return Number((weddingAlbum10PriceElopements * taxRate).toFixed(2));
  };

  const calculateWeddingAlbum12TaxElopements = () => {
    return Number((weddingAlbum12PriceElopements * taxRate).toFixed(2));
  };
  //#endregion

  // TODO: refactor uncomment
  //#region package price, weddings, elopements, storage
  // useEffect(() => {
  //   console.log(
  //     'PACKAGE PRICE',
  //     packagePrice,
  //     discountAmount,
  //     weddingAlbum10Tax,
  //     weddingAlbum12Tax,
  //     photoBoothBasicTax,
  //     photoBoothAllInclusiveTax
  //   );

  //   console.log(
  //     'CONFIGURATOR:',
  //     packageConfiguratorWeddings,
  //     packageConfiguratorElopements,
  //     packageConfiguratorStorage
  //   );
  //   if (packageConfiguratorWeddings) {
  //     console.log('PACKAGE CONF WEDDINGS');
  //     const combinedWeddings =
  //       Number(grandTotalPrice) -
  //       //   TODO: recheck for other values
  //       //   Number(discountAmount) -
  //       Number(weddingAlbum10Tax) -
  //       Number(weddingAlbum12Tax) -
  //       Number(photoBoothBasicTax) -
  //       Number(photoBoothAllInclusiveTax) -
  //       Number(ccFee);
  //     if (
  //       discountApplied ||
  //       weddingAlbum10Selected ||
  //       weddingAlbum12Selected ||
  //       photoBoothBasicSelected ||
  //       photoBoothAllInclusiveSelected
  //     ) {
  //       setPackagePrice(Number(combinedWeddings.toFixed(2)));
  //     } else {
  //       setPackagePrice(Number(packagePrice));
  //     }
  //   }
  //   if (packageConfiguratorElopements) {
  //     console.log('PACKAGE CONF ELOPEMENTS', packagePriceElopements);
  //     const combinedElopements =
  //       Number(grandTotalPriceElopements) -
  //       //   TODO: recheck for other values
  //       //   Number(discountAmount) -
  //       Number(weddingAlbum10TaxElopements) -
  //       Number(weddingAlbum12TaxElopements) -
  //       Number(ccFeeElopements);
  //     if (
  //       // discountApplied ||
  //       weddingAlbum10SelectedElopements ||
  //       weddingAlbum12SelectedElopements
  //     ) {
  //       setPackagePriceElopements(Number(combinedElopements.toFixed(2)));
  //     } else {
  //       setPackagePriceElopements(Number(packagePriceElopements));
  //     }
  //   }
  //   if (packageConfiguratorStorage) {
  //     console.log('PACKAGE CONF STORAGE');
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [
  //   // weddings
  //   packagePrice,
  //   discountApplied,
  //   discountAmount,
  //   weddingAlbum10Selected,
  //   weddingAlbum10Tax,
  //   weddingAlbum12Selected,
  //   weddingAlbum12Tax,
  //   photoBoothBasicSelected,
  //   photoBoothBasicTax,
  //   photoBoothAllInclusiveSelected,
  //   photoBoothAllInclusiveTax,
  //   grandTotalPrice,
  //   // elopements
  //   packagePriceElopements,
  //   // discountApplied,
  //   // discountAmount,
  //   weddingAlbum10SelectedElopements,
  //   weddingAlbum10TaxElopements,
  //   weddingAlbum12SelectedElopements,
  //   weddingAlbum12TaxElopements,
  //   photoBoothBasicSelected,
  //   grandTotalPriceElopements,
  //   // storage
  // ]);
  //#endregion

  // payment method
  const [paymentMethod, setPaymentMethod] = useState();
  const [ccRate] = useState(0.0357); // 3.57%

  //#region fees weddings
  const [ccFee, setFee] = useState(0);
  const [ccFeeImmutable, setFeeImmutable] = useState(0);

  // balance
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    // console.log(
    //   'discountApplied, packagePrice, grandTotalPrice',
    //   discountApplied,
    //   packagePrice,
    //   grandTotalPrice
    // );
    // if (discountApplied) {
    //   setFee(Number(packagePrice * ccRate).toFixed(2));
    // } else {
    //   setFee(Number(grandTotalPrice * ccRate).toFixed(2));
    // }
    setFeeImmutable(Number((packagePrice + tax) * ccRate).toFixed(2));

    if (paymentMethod === 'creditCard' || paymentMethod === 'payPal') {
      setFee(Number((packagePrice + tax) * ccRate).toFixed(2));
      //   setFee(Number(grandTotalPrice * ccRate).toFixed(2));
    } else {
      setFee(0);
    }
    // console.log('ccFee, paymentMethod', ccFee, paymentMethod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grandTotalPrice, paymentMethod]);
  // }, [discountApplied, grandTotalPrice, packagePrice]);

  useEffect(() => {
    if (grandTotalPrice > 0) {
      const calculateBalance = grandTotalPrice - depositPrice;
      setBalance(Number(calculateBalance).toFixed(2));
    }
  }, [grandTotalPrice, depositPrice]);
  // }, [grandTotalPrice, depositPrice, balance]);
  //#endregion

  //#region Calculate grand total weddings
  const calculateGrandTotal = () => {
    // gatsbyjs ssr fix build error
    if (typeof document !== 'undefined') {
      let totalPrice;
      const packagePriceElements = document.querySelectorAll('.item-price');

      const packagePriceMapped = Array.from(packagePriceElements).map((el) => {
        return Number(el.textContent);
      });

      // add all prices
      totalPrice = packagePriceMapped.reduce((a, b) => a + b, 0);
      setPackagePrice(Number(totalPrice));
      // console.log('GRAND TOTAL PRICE', totalPrice, tax, ccFee);
      // TODO: .toFixed(2)
      // const newGrandTotalPrice = Number(totalPrice) + Number(tax);
      // setGrandTotalPrice(newGrandTotalPrice);
      setGrandTotalPrice(Number(totalPrice) + Number(tax));
      // setGrandTotalPrice(totalPrice + Number(tax) + Number(ccFee));
      if (paymentMethod === 'creditCard' || paymentMethod === 'payPal') {
        setGrandTotalPrice(
          (Number(totalPrice) + Number(tax) + Number(ccFee)).toFixed(2)
        );
      }
      // console.log('PAYMENT METHOD', paymentMethod);
    }
  };

  useEffect(() => {
    calculateGrandTotal();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // weddings
    onePhotographerSelected,
    // onePhotographerHour,
    onePhotographerPrice,
    secondPhotographerSelected,
    secondPhotographerPrice,
    engagementPhotoShootSelected,
    engagementPhotoShootPrice,
    weddingAlbum10Selected,
    weddingAlbum10PriceTotal,
    weddingAlbum12Selected,
    weddingAlbum12PriceTotal,
    photoRetouchingSelected,
    photoRetouchingPackagePrice,
    expeditedPhotoProcessingSelected,
    expeditedPhotoProcessingPrice,
    oneVideographerSelected,
    oneVideographerPrice,
    secondVideographerSelected,
    secondVideographerPrice,
    cinematographySelected,
    cinematographyPrice,
    trailerOnlySelected,
    trailerOnlyPrice,
    extendedTrailerSelected,
    extendedTrailerPrice,
    traditionalSelected,
    traditionalPrice,
    photoBoothBasicSelected,
    photoBoothAllInclusiveSelected,
    photoBoothPackagePrice,
    // storage
    storagePackageSelected,
    storagePackagePrice,
    // premium
    premiumPhotoPackageSelected,
    premiumVideoPackageSelected,
    tax,
    ccFee,
    paymentMethod,
    // sneak peek
    socialMediaPackagePhotoSelected,
    socialMediaPackagePhotoPrice,
    socialMediaPackageVideoSelected,
    socialMediaPackageVideoPrice,
    socialMediaPackagePhotoAndVideoSelected,
    socialMediaPackagePhotoAndVideoPrice,
  ]);
  //#endregion

  //#region fees elopements
  const [ccFeeElopements, setFeeElopements] = useState(0);
  const [ccFeeImmutableElopements, setFeeImmutableElopements] = useState(0);

  // balance
  const [balanceElopements, setBalanceElopements] = useState(0);

  useEffect(() => {
    // console.log(
    //   'discountApplied, packagePrice, grandTotalPrice',
    //   discountApplied,
    //   packagePrice,
    //   grandTotalPrice
    // );
    // if (discountApplied) {
    //   setFee(Number(packagePrice * ccRate).toFixed(2));
    // } else {
    //   setFee(Number(grandTotalPrice * ccRate).toFixed(2));
    // }
    setFeeImmutableElopements(
      Number((packagePriceElopements + taxElopements) * ccRate).toFixed(2)
    );

    if (paymentMethod === 'creditCard' || paymentMethod === 'payPal') {
      setFeeElopements(
        Number((packagePriceElopements + taxElopements) * ccRate).toFixed(2)
      );
      //   setFee(Number(grandTotalPrice * ccRate).toFixed(2));
    } else {
      setFeeElopements(0);
    }
    // console.log('ccFee, paymentMethod', ccFee, paymentMethod);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [grandTotalPriceElopements, paymentMethod]);
  // }, [discountApplied, grandTotalPrice, packagePrice]);

  useEffect(() => {
    if (grandTotalPriceElopements > 0) {
      const calculateBalance =
        grandTotalPriceElopements - depositPriceElopements;
      setBalanceElopements(Number(calculateBalance).toFixed(2));
    }
  }, [grandTotalPriceElopements, depositPriceElopements]);
  // }, [grandTotalPrice, depositPrice, balance]);
  //#endregion

  //#region Calculate grand total elopements
  const calculateGrandTotalElopements = () => {
    // gatsby build ssr error fix
    if (typeof document !== 'undefined') {
      let totalPriceElopements;

      const packagePriceElementsElopements = document.querySelectorAll(
        '.item-price-elopements'
      );

      const packagePriceMappedElopements = Array.from(
        packagePriceElementsElopements
      ).map((el) => {
        return Number(el.textContent);
      });

      // add all prices
      totalPriceElopements = packagePriceMappedElopements.reduce(
        (a, b) => a + b,
        0
      );
      // console.log('TOTAL PRICE ELOPEMENTS', totalPriceElopements);
      setPackagePriceElopements(Number(totalPriceElopements));
      setGrandTotalPriceElopements(
        Number(totalPriceElopements) + Number(taxElopements)
      );
      if (paymentMethod === 'creditCard' || paymentMethod === 'payPal') {
        setGrandTotalPriceElopements(
          (
            Number(totalPriceElopements) +
            Number(taxElopements) +
            Number(ccFeeElopements)
          ).toFixed(2)
        );
      }
    }
  };

  // grand total price elopements
  useEffect(() => {
    calculateGrandTotalElopements();
    console.log('CCFEE, TAX', ccFeeElopements, taxElopements);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // elopements
    onePhotographerSelectedElopements,
    onePhotographerPriceElopements,
    weddingAlbum10SelectedElopements,
    weddingAlbum10PriceTotalElopements,
    weddingAlbum12SelectedElopements,
    weddingAlbum12PriceTotalElopements,
    oneVideographerSelectedElopements,
    oneVideographerPriceElopements,
    officiantSelectedElopements,
    officiantPriceElopements,
    // storage
    storagePackageSelectedElopements,
    storageElopementsPackagePrice,
    // premium
    premiumPhotoPackageSelectedElopements,
    premiumVideoPackageSelectedElopements,
    taxElopements,
    ccFeeElopements,
    paymentMethod,
    // sneak peek
    socialMediaPackagePhotoSelectedElopements,
    socialMediaPackagePhotoPriceElopements,
    socialMediaPackageVideoSelectedElopements,
    socialMediaPackageVideoPriceElopements,
    socialMediaPackagePhotoAndVideoSelectedElopements,
    socialMediaPackagePhotoAndVideoPriceElopements,
  ]);
  //#endregion

  //#region Show sidebars
  const [showSidebar, setShowSidebar] = useState(false);
  const [showSidebarElopements, setShowSidebarElopements] = useState(false);

  const checkForPackagesSelected = () => {
    // only account for parent packages
    if (
      onePhotographerSelected ||
      engagementOneHourSelected ||
      engagementTwoHoursSelected ||
      oneVideographerSelected ||
      photoBoothBasicSelected ||
      photoBoothAllInclusiveSelected ||
      // added
      weddingAlbum10Selected ||
      weddingAlbum12Selected ||
      photoRetouching10Selected ||
      photoRetouching20Selected ||
      photoRetouching30Selected ||
      expeditedPhotoProcessingSelected ||
      // sneak peek
      socialMediaPackagePhotoSelected ||
      socialMediaPackageVideoSelected ||
      socialMediaPackagePhotoAndVideoSelected
    ) {
      setShowSidebar(true);
    } else {
      setShowSidebar(false);
    }
  };

  const checkForPackagesSelectedElopements = () => {
    if (
      // only account for parent packages
      onePhotographerSelectedElopements ||
      oneVideographerSelectedElopements ||
      // sneak peek
      socialMediaPackagePhotoSelectedElopements ||
      socialMediaPackageVideoSelectedElopements ||
      socialMediaPackagePhotoAndVideoSelectedElopements ||
      officiantSelectedElopements
    ) {
      setShowSidebarElopements(true);
    } else {
      setShowSidebarElopements(false);
    }
  };

  useEffect(() => {
    checkForPackagesSelected();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // weddings
    onePhotographerSelected,
    engagementOneHourSelected,
    engagementTwoHoursSelected,
    oneVideographerSelected,
    photoBoothBasicSelected,
    photoBoothAllInclusiveSelected,
    // added
    weddingAlbum10Selected,
    weddingAlbum12Selected,
    photoRetouching10Selected,
    photoRetouching20Selected,
    photoRetouching30Selected,
    expeditedPhotoProcessingSelected,
    // sneak peek
    socialMediaPackagePhotoSelected,
    socialMediaPackageVideoSelected,
    socialMediaPackagePhotoAndVideoSelected,
  ]);

  useEffect(() => {
    checkForPackagesSelectedElopements();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    // elopements
    onePhotographerSelectedElopements,
    oneVideographerSelectedElopements,
    officiantSelectedElopements,
    // added
    weddingAlbum10SelectedElopements,
    weddingAlbum12SelectedElopements,
    // sneak peek
    socialMediaPackagePhotoSelectedElopements,
    socialMediaPackageVideoSelectedElopements,
    socialMediaPackagePhotoAndVideoSelectedElopements,
  ]);
  //#endregion

  //#region On mount recalculates the price
  // of all the packages for weddings and elopements
  useState(() => {
    console.log('CONFIGURATOR PROVIDER ACTIVE');
    // TODO: recheck this
    // calculateWeddingAlbum10Tax();
    // weddings
    handleOnePhotographerPrice();
    handleSecondPhotographerPrice();
    handlePriceTotalWeddingAlbum10(weddingAlbum10Amount);
    handlePriceTotalWeddingAlbum12(weddingAlbum12Amount);
    handleEngagementsPricing();
    handlePhotoRetouchingPricing();
    handleOneVideographerPrice();
    handleSecondVideographerPrice();
    handleStoragePrice();
    // elopements
    handleOnePhotographerPriceElopements();
    handleOneVideographerPriceElopements();
    handleOfficiantPriceElopements();
    handlePriceTotalWeddingAlbum10Elopements(weddingAlbum10AmountElopements);
    handlePriceTotalWeddingAlbum12Elopements(weddingAlbum12AmountElopements);
    handleStoragePriceElopements();
    // packages storage
    handlePackagesStoragePricing();
    // calculate grand totals
    calculateGrandTotal();
    calculateGrandTotalElopements();
  }, []);
  //#endregion

  return (
    <ConfiguratorContext.Provider
      value={{
        location,
        isDev,
        handleKeyUp,
        isMobile,
        showPackage,
        showPackageElopements,
        showPackageStorage,
        discountApplied,
        discountAmount,
        setGrandTotalPrice,
        grandTotalPrice,
        calculateGrandTotal: () => calculateGrandTotal(),
        setGrandTotalPriceElopements,
        grandTotalPriceElopements,
        calculateGrandTotalElopements: () => calculateGrandTotalElopements(),
        showSidebarElopements,
        setGrandTotalPriceStorage,
        grandTotalPriceStorage,
        showSidebar,
        depositPriceElopements,
        packagePrice,
        discountValue,
        depositPrice,
        balance,
        tax,
        ccFee,
        ccFeeImmutable,
        packagePriceElopements,
        taxElopements,
        ccFeeElopements,
        ccFeeImmutableElopements,
        balanceElopements,
        setPaymentMethod,
        setShowPackage,
        setShowPackageElopements,
        setShowPackageStorage,
        togglePackage: (ev) => togglePackage(ev),
        togglePackageElopements: (ev) => togglePackageElopements(ev),
        togglePackageStorage: (ev) => togglePackageStorage(ev),
        // weddings state
        ...packageConfiguratorWeddingsState,
        // weddings methods
        handleCinematographySelected: (ev) =>
          handleCinematographySelected(ev, packageConfiguratorWeddingsState),
        handleTrailerOnlySelected: (ev) =>
          handleTrailerOnlySelected(ev, packageConfiguratorWeddingsState),
        handleExtendedTrailerSelected: (ev) =>
          handleExtendedTrailerSelected(ev, packageConfiguratorWeddingsState),
        handleTraditionalSelected: (ev) =>
          handleTraditionalSelected(ev, packageConfiguratorWeddingsState),
        handleSocialMediaPackagePhotoSelected: (ev) =>
          handleSocialMediaPackagePhotoSelected(
            ev,
            packageConfiguratorWeddingsState
          ),
        handleSocialMediaPackageVideoSelected: (ev) =>
          handleSocialMediaPackageVideoSelected(
            ev,
            packageConfiguratorWeddingsState
          ),
        handleSocialMediaPackagePhotoAndVideoSelected: (ev) =>
          handleSocialMediaPackagePhotoAndVideoSelected(
            ev,
            packageConfiguratorWeddingsState
          ),
        handleOnePhotographerSelected: (ev) =>
          handleOnePhotographerSelected(ev),
        handleOnePhotographerData: (control, ev) =>
          handleOnePhotographerData(
            control,
            ev,
            packageConfiguratorWeddingsState
          ),
        handleOnePhotographerPrice: () => handleOnePhotographerPrice(),
        handleSecondPhotographerSelected: (ev) =>
          handleSecondPhotographerSelected(
            ev,
            packageConfiguratorWeddingsState
          ),
        handleSecondPhotographerData: (control, ev) =>
          handleSecondPhotographerData(
            control,
            ev,
            packageConfiguratorWeddingsState
          ),
        handleSecondPhotographerPrice: () => handleSecondPhotographerPrice(),
        handleEngagementPhotoshoot: (option, ev) =>
          handleEngagementPhotoshoot(
            option,
            ev,
            packageConfiguratorWeddingsState
          ),
        handleEngagementPhotoshootSelected: () =>
          handleEngagementPhotoshootSelected(packageConfiguratorWeddingsState),
        handleOneVideographerSelected: (ev) =>
          handleOneVideographerSelected(ev),
        handleOneVideographerData: (control, ev) =>
          handleOneVideographerData(
            control,
            ev,
            packageConfiguratorWeddingsState
          ),
        handleOneVideographerPrice: () => handleOneVideographerPrice(),
        handleSecondVideographerSelected: (ev) =>
          handleSecondVideographerSelected(
            ev,
            packageConfiguratorWeddingsState
          ),
        handleSecondVideographerData: (control, ev) =>
          handleSecondVideographerData(
            control,
            ev,
            packageConfiguratorWeddingsState
          ),
        handleSecondVideographerPrice: () => handleSecondVideographerPrice(),
        handleWeddingAlbumSelected: (album) =>
          handleWeddingAlbumSelected(album, packageConfiguratorWeddingsState),
        handleWeddingAlbumProfessionalDesignSelected: (album) =>
          handleWeddingAlbumProfessionalDesignSelected(
            album,
            packageConfiguratorWeddingsState
          ),
        handleWeddingAlbumAmount: (album, control) =>
          handleWeddingAlbumAmount(
            album,
            control,
            packageConfiguratorWeddingsState
          ),
        handleWeddingAlbumProfessionalDesignAmount: (album, control) =>
          handleWeddingAlbumProfessionalDesignAmount(
            album,
            control,
            packageConfiguratorWeddingsState
          ),
        handlePriceTotalWeddingAlbum10: (w10a) =>
          handlePriceTotalWeddingAlbum10(w10a),
        handlePriceTotalWeddingAlbum12: (w12a) =>
          handlePriceTotalWeddingAlbum12(w12a),
        handlePhotoRetouchingSelected: () =>
          handlePhotoRetouchingSelected(packageConfiguratorWeddingsState),
        handlePhotoRetouching: (option, ev) =>
          handlePhotoRetouching(option, ev, packageConfiguratorWeddingsState),
        handleExpeditedPhotoProcessing: (option, ev) =>
          handleExpeditedPhotoProcessing(
            option,
            ev,
            packageConfiguratorWeddingsState
          ),
        handlePhotoBoothSelected: (option) =>
          handlePhotoBoothSelected(option, packageConfiguratorWeddingsState),
        // elopements state
        ...packageConfiguratorElopementsState,
        // elopements methods
        handleOnePhotographerPriceElopements: () =>
          handleOnePhotographerPriceElopements(),
        handleWeddingAlbumAmountElopements: (album, control) =>
          handleWeddingAlbumAmountElopements(
            album,
            control,
            packageConfiguratorElopementsState
          ),
        handleOfficiantSelectedElopements: (ev) =>
          handleOfficiantSelectedElopements(
            ev,
            packageConfiguratorElopementsState
          ),
        handleOnePhotographerSelectedElopements: (ev) =>
          handleOnePhotographerSelectedElopements(ev),
        handleOnePhotographerDataElopements: (control, ev) =>
          handleOnePhotographerDataElopements(
            control,
            ev,
            packageConfiguratorElopementsState
          ),
        handleOneVideographerSelectedElopements: (ev) =>
          handleOneVideographerSelectedElopements(ev),
        handleOneVideographerDataElopements: (control, ev) =>
          handleOneVideographerDataElopements(
            control,
            ev,
            packageConfiguratorElopementsState
          ),
        handleOneVideographerPriceElopements: () =>
          handleOneVideographerPriceElopements(),
        handleWeddingAlbumSelectedElopements: (album) =>
          handleWeddingAlbumSelectedElopements(
            album,
            packageConfiguratorElopementsState
          ),
        handleWeddingAlbumProfessionalDesignSelectedElopements: (album) =>
          handleWeddingAlbumProfessionalDesignSelectedElopements(
            album,
            packageConfiguratorElopementsState
          ),
        handleWeddingAlbumProfessionalDesignAmountElopements: (
          album,
          control
        ) =>
          handleWeddingAlbumProfessionalDesignAmountElopements(
            album,
            control,
            packageConfiguratorElopementsState
          ),
        handlePriceTotalWeddingAlbum10Elopements: (w10a) =>
          handlePriceTotalWeddingAlbum10Elopements(w10a),
        handlePriceTotalWeddingAlbum12Elopements: (w12a) =>
          handlePriceTotalWeddingAlbum12Elopements(w12a),
        handleOfficiantPriceElopements: () => handleOfficiantPriceElopements(),
        // weddings, elopements modal data
        weddingAlbumInfoModal,
        professionalAlbumDesignInfoModal,
        weddingAlbumInfoModalElopements,
        professionalAlbumDesignInfoModalElopements,
        cinematographyInfo,
        trailerOnlyInfo,
        extendedTrailerInfo,
        traditionalInfo,
        photoBoothInfo,
        // photoRetouchingInfo,
        expeditedPhotoProcessingInfo,
        // storage modal
        checkedStorageValueModal,
        checkedStorageValueModalElopements,
        // setCheckedStorageValueModal,
        toggleCheckoutBtnInfo,
        setToggleCheckoutBtnInfo,
        // storage
        checkedStorageValue,
        storagePackageSelected,
        storageInfo,
        storagePackageType,
        storagePackagePrice,
        storagePhotoPackageInfo,
        storagePhotoPackageOption1PopupText,
        storagePhotoPackageOption1SidebarText,
        storagePhotoPackageOption1Price,
        storagePhotoPackageOption2PopupText,
        storagePhotoPackageOption2SidebarText,
        storagePhotoPackageOption2Price,
        storagePhotoPackageOption3PopupText,
        storagePhotoPackageOption3SidebarText,
        storagePhotoPackageOption3Price,
        storageVideoPackageInfo,
        storageVideoPackageOption1PopupText,
        storageVideoPackageOption1SidebarText,
        storageVideoPackageOption1Price,
        storageVideoPackageOption2PopupText,
        storageVideoPackageOption2SidebarText,
        storageVideoPackageOption2Price,
        storageVideoPackageOption3PopupText,
        storageVideoPackageOption3SidebarText,
        storageVideoPackageOption3Price,
        storagePhotoAndVideoPackageInfo,
        storagePhotoAndVideoPackageOption1PopupText,
        storagePhotoAndVideoPackageOption1SidebarText,
        storagePhotoAndVideoPackageOption1Price,
        storagePhotoAndVideoPackageOption2PopupText,
        storagePhotoAndVideoPackageOption2SidebarText,
        storagePhotoAndVideoPackageOption2Price,
        storagePhotoAndVideoPackageOption3PopupText,
        storagePhotoAndVideoPackageOption3SidebarText,
        storagePhotoAndVideoPackageOption3Price,
        setStoragePackageType,
        setStoragePackageSelected,
        setStoragePackagePrice,
        handleStoragePackageSelected: () => handleStoragePackageSelected(),
        handleStoragePackage: (type) => handleStoragePackage(type),
        handleStoragePackageChange: (price, ev) =>
          handleStoragePackageChange(price, ev),
        // storage elopements
        storagePackageSelectedElopements,
        storageElopementsInfo,
        storagePackageTypeElopements,
        storageElopementsPackagePrice,
        storageElopementsPhotoPackageInfo,
        storageElopementsPhotoPackageOption1PopupText,
        storageElopementsPhotoPackageOption1SidebarText,
        storageElopementsPhotoPackageOption1Price,
        storageElopementsPhotoPackageOption2PopupText,
        storageElopementsPhotoPackageOption2SidebarText,
        storageElopementsPhotoPackageOption2Price,
        storageElopementsPhotoPackageOption3PopupText,
        storageElopementsPhotoPackageOption3SidebarText,
        storageElopementsPhotoPackageOption3Price,
        storageElopementsVideoPackageInfo,
        storageElopementsVideoPackageOption1PopupText,
        storageElopementsVideoPackageOption1SidebarText,
        storageElopementsVideoPackageOption1Price,
        storageElopementsVideoPackageOption2PopupText,
        storageElopementsVideoPackageOption2SidebarText,
        storageElopementsVideoPackageOption2Price,
        storageElopementsVideoPackageOption3PopupText,
        storageElopementsVideoPackageOption3SidebarText,
        storageElopementsVideoPackageOption3Price,
        storageElopementsPhotoAndVideoPackageInfo,
        storageElopementsPhotoAndVideoPackageOption1PopupText,
        storageElopementsPhotoAndVideoPackageOption1SidebarText,
        storageElopementsPhotoAndVideoPackageOption1Price,
        storageElopementsPhotoAndVideoPackageOption2PopupText,
        storageElopementsPhotoAndVideoPackageOption2SidebarText,
        storageElopementsPhotoAndVideoPackageOption2Price,
        storageElopementsPhotoAndVideoPackageOption3PopupText,
        storageElopementsPhotoAndVideoPackageOption3SidebarText,
        storageElopementsPhotoAndVideoPackageOption3Price,
        checkedStorageValueElopements,
        setStoragePackageTypeElopements,
        setStoragePackageSelectedElopements,
        setStorageElopementsPackagePrice,
        handleStoragePackageSelectedElopements: () =>
          handleStoragePackageSelectedElopements(),
        handleStoragePackageElopements: (type) =>
          handleStoragePackageElopements(type),
        handleStorageElopementsPackageChange: (e, price) =>
          handleStorageElopementsPackageChange(e, price),
        // local storage
        packageConfiguratorWeddings,
        setPackageConfiguratorWeddings,
        packageConfiguratorElopements,
        setPackageConfiguratorElopements,
        packageConfiguratorStorage,
        setPackageConfiguratorStorage,
        // packages storage
        photoDownloadLinkSelected,
        videoDownloadLinkSelected,
        photoVideoDownloadLinkSelected,
        setPhotoDownloadLinkSelected,
        setVideoDownloadLinkSelected,
        setPhotoVideoDownloadLinkSelected,
        photoDownloadLinkPrice,
        videoDownloadLinkPrice,
        photoVideoDownloadLinkPrice,
        packagesStorageSelected,
        packagesStoragePrice,
        setPackagesStoragePrice,
        handlePackagesStorageSelected: (ev) =>
          handlePackagesStorageSelected(ev),
        handlePackagesStorage: (option, ev) =>
          handlePackagesStorage(option, ev),
        // premium
        handlePremiumPhotoPackageSelected: (ev) =>
          handlePremiumPhotoPackageSelected(
            ev,
            packageConfiguratorWeddingsState
          ),
        handlePremiumVideoPackageSelected: (ev) =>
          handlePremiumVideoPackageSelected(
            ev,
            packageConfiguratorWeddingsState
          ),
        isPremiumPackageAdded,
        setIsPremiumPackageAdded,
        // premium elopements
        handlePremiumPhotoPackageSelectedElopements: (ev) =>
          handlePremiumPhotoPackageSelectedElopements(
            ev,
            packageConfiguratorElopementsState
          ),
        handlePremiumVideoPackageSelectedElopements: (ev) =>
          handlePremiumVideoPackageSelectedElopements(
            ev,
            packageConfiguratorElopementsState
          ),
        isPremiumPackageAddedElopements,
        // social media package (sneak peek) elopements
        setSocialMediaPackagePhotoAndVideoSelectedElopements,
        handleSocialMediaPackagePhotoSelectedElopements: (ev) =>
          handleSocialMediaPackagePhotoSelectedElopements(
            ev,
            packageConfiguratorElopementsState
          ),
        handleSocialMediaPackageVideoSelectedElopements: (ev) =>
          handleSocialMediaPackageVideoSelectedElopements(
            ev,
            packageConfiguratorElopementsState
          ),
        handleSocialMediaPackagePhotoAndVideoSelectedElopements: (ev) =>
          handleSocialMediaPackagePhotoAndVideoSelectedElopements(
            ev,
            packageConfiguratorElopementsState
          ),
        // premium modals
        premiumPhotoPackageModalActive,
        togglePremiumPhotoPackageModal: (ev) =>
          togglePremiumPhotoPackageModal(ev),
        premiumVideoPackageModalActive,
        togglePremiumVideoPackageModal: (ev) =>
          togglePremiumVideoPackageModal(ev),
      }}
    >
      {children}
    </ConfiguratorContext.Provider>
  );
};
