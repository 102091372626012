// Helper methods
const handlePreventDefault = (ev) => {
  if (ev.keyCode === 13) {
    ev.preventDefault();
  }
};

//#region One Photographer
// TODO: accessibility state
export const handleOnePhotographerDataElopements = (control, ev, state) => {
  const {
    onePhotographerHourElopements,
    setOnePhotographerHourElopements,
    setOnePhotographerControlElopements,
    onePhotographerSelectedElopements,
    setOnePhotographerSelectedElopements,
  } = state;

  handlePreventDefault(ev);

  // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
  if (onePhotographerSelectedElopements === false) {
    setOnePhotographerSelectedElopements(true);
  }

  if (control === 'decrease') {
    setOnePhotographerControlElopements('decrease');
    if (onePhotographerHourElopements > 0) {
      setOnePhotographerHourElopements(onePhotographerHourElopements - 1);
    }
  }

  if (control === 'increase') {
    if (onePhotographerHourElopements < 4) {
      setOnePhotographerControlElopements('increase');
      setOnePhotographerHourElopements(onePhotographerHourElopements + 1);
    }
  }
  // }

  // handleKeyPressed(ev.keyCode);
};
//#endregion
//#region Wedding Album
// TODO: accessibility state
export const handleWeddingAlbumSelectedElopements = (album, state) => {
  const {
    weddingAlbum10SelectedElopements,
    weddingAlbum12SelectedElopements,
    setWeddingAlbum10SelectedElopements,
    setWeddingAlbum12SelectedElopements,
  } = state;

  if (album === '10x10') {
    setWeddingAlbum10SelectedElopements(!weddingAlbum10SelectedElopements);
  }
  if (album === '12x12') {
    setWeddingAlbum12SelectedElopements(!weddingAlbum12SelectedElopements);
  }
};
// TODO: accessibility state
export const handleWeddingAlbumProfessionalDesignSelectedElopements = (
  album,
  state
) => {
  const {
    weddingAlbum10SelectedElopements,
    weddingAlbum12SelectedElopements,
    weddingAlbum10ProfessionalDesignSelectedElopements,
    weddingAlbum12ProfessionalDesignSelectedElopements,
    setWeddingAlbum10ProfessionalDesignSelectedElopements,
    setWeddingAlbum12ProfessionalDesignSelectedElopements,
  } = state;

  if (album === '10x10') {
    if (weddingAlbum10SelectedElopements) {
      setWeddingAlbum10ProfessionalDesignSelectedElopements(
        !weddingAlbum10ProfessionalDesignSelectedElopements
      );
    }
  }
  if (album === '12x12') {
    if (weddingAlbum12SelectedElopements) {
      setWeddingAlbum12ProfessionalDesignSelectedElopements(
        !weddingAlbum12ProfessionalDesignSelectedElopements
      );
    }
  }
};
// TODO: accessibility state
export const handleWeddingAlbumAmountElopements = (album, control, state) => {
  const {
    weddingAlbum10AmountElopements,
    weddingAlbum12AmountElopements,
    weddingAlbum10SelectedElopements,
    setWeddingAlbum10SelectedElopements,
    weddingAlbum12SelectedElopements,
    setWeddingAlbum12SelectedElopements,
    setWeddingAlbum10AmountElopements,
    setWeddingAlbum12AmountElopements,
    setWeddingAlbum10ControlElopements,
    setWeddingAlbum12ControlElopements,
    setWeddingAlbum10ProfessionalDesignAmountElopements,
    setWeddingAlbum12ProfessionalDesignAmountElopements,
    setWeddingAlbum10AmountFreeElopements,
    setWeddingAlbum12AmountFreeElopements,
  } = state;

  if (album === '10x10') {
    setWeddingAlbum10AmountFreeElopements(0);

    if (weddingAlbum10SelectedElopements === false) {
      setWeddingAlbum10SelectedElopements(true);
    }
    if (control === 'increase') {
      setWeddingAlbum10ControlElopements(control);
      setWeddingAlbum10AmountElopements(weddingAlbum10AmountElopements + 1);
      setWeddingAlbum10ProfessionalDesignAmountElopements(
        weddingAlbum10AmountElopements + 1
      );
      setWeddingAlbum10AmountFreeElopements(0);
    }

    if (control === 'decrease') {
      setWeddingAlbum10ControlElopements(control);
      if (weddingAlbum10AmountElopements > 1) {
        setWeddingAlbum10AmountElopements(weddingAlbum10AmountElopements - 1);
      }
    }
  }

  if (album === '12x12') {
    setWeddingAlbum12AmountFreeElopements(0);

    if (weddingAlbum12SelectedElopements === false) {
      setWeddingAlbum12SelectedElopements(true);
    }
    if (control === 'increase') {
      setWeddingAlbum12ControlElopements(control);
      setWeddingAlbum12AmountElopements(weddingAlbum12AmountElopements + 1);
      setWeddingAlbum12ProfessionalDesignAmountElopements(
        weddingAlbum12AmountElopements + 1
      );
      setWeddingAlbum12AmountFreeElopements(0);
    }

    if (control === 'decrease') {
      setWeddingAlbum12ControlElopements(control);
      if (weddingAlbum12AmountElopements > 1) {
        setWeddingAlbum12AmountElopements(weddingAlbum12AmountElopements - 1);
      }
    }
  }
};
// TODO: accsessibility state
export const handleWeddingAlbumProfessionalDesignAmountElopements = (
  album,
  control,
  state
) => {
  const {
    weddingAlbum10AmountElopements,
    weddingAlbum10ProfessionalDesignAmountElopements,
    weddingAlbum10AmountFreeElopements,
    weddingAlbum12AmountElopements,
    weddingAlbum12ProfessionalDesignAmountElopements,
    weddingAlbum12AmountFreeElopements,
    setWeddingAlbum10ProfessionalDesignAmountElopements,
    setWeddingAlbum12ProfessionalDesignAmountElopements,
    setWeddingAlbum10AmountFreeElopements,
    setWeddingAlbum12AmountFreeElopements,
  } = state;

  if (album === '10x10') {
    if (control === 'increase') {
      if (
        weddingAlbum10AmountElopements >
        weddingAlbum10ProfessionalDesignAmountElopements
      ) {
        setWeddingAlbum10ProfessionalDesignAmountElopements(
          weddingAlbum10ProfessionalDesignAmountElopements + 1
        );
      }
      if (
        weddingAlbum10AmountFreeElopements > 0 &&
        weddingAlbum10AmountFreeElopements !== weddingAlbum10AmountElopements
      ) {
        setWeddingAlbum10AmountFreeElopements(
          weddingAlbum10AmountFreeElopements - 1
        );
      }
    }

    if (control === 'decrease') {
      if (weddingAlbum10AmountElopements > weddingAlbum10AmountFreeElopements) {
        setWeddingAlbum10AmountFreeElopements(
          weddingAlbum10AmountFreeElopements + 1
        );
      }
      setWeddingAlbum10ProfessionalDesignAmountElopements(
        weddingAlbum10ProfessionalDesignAmountElopements - 1
      );
    }
  }

  if (album === '12x12') {
    if (control === 'increase') {
      if (
        weddingAlbum12AmountElopements >
        weddingAlbum12ProfessionalDesignAmountElopements
      ) {
        setWeddingAlbum12ProfessionalDesignAmountElopements(
          weddingAlbum12ProfessionalDesignAmountElopements + 1
        );
      }
      if (
        weddingAlbum12AmountFreeElopements > 0 &&
        weddingAlbum12AmountFreeElopements !== weddingAlbum12AmountElopements
      ) {
        setWeddingAlbum12AmountFreeElopements(
          weddingAlbum12AmountFreeElopements - 1
        );
      }
    }

    if (control === 'decrease') {
      if (weddingAlbum12AmountElopements > weddingAlbum12AmountFreeElopements) {
        setWeddingAlbum12AmountFreeElopements(
          weddingAlbum12AmountFreeElopements + 1
        );
      }

      setWeddingAlbum12ProfessionalDesignAmountElopements(
        weddingAlbum12ProfessionalDesignAmountElopements - 1
      );
    }
  }
};
//#endregion
//#region Premium Package Elopements
// TODO: accsessibility state
export const handlePremiumPhotoPackageSelectedElopements = (ev, state) => {
  const {
    premiumPhotoPackageSelectedElopements,
    setPremiumPhotoPackageSelectedElopements,
  } = state;

  // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
  setPremiumPhotoPackageSelectedElopements(
    !premiumPhotoPackageSelectedElopements
  );
  //   handleKeyPressed(ev.keyCode);
  // }
};
// TODO: accsessibility state
export const handlePremiumVideoPackageSelectedElopements = (ev, state) => {
  const {
    premiumVideoPackageSelectedElopements,
    setPremiumVideoPackageSelectedElopements,
  } = state;

  // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
  setPremiumVideoPackageSelectedElopements(
    !premiumVideoPackageSelectedElopements
  );
  //   handleKeyPressed(ev.keyCode);
  // }
};
//#endregion
//#region One Videographer
// TODO: accsessibility state
export const handleOneVideographerDataElopements = (control, ev, state) => {
  const {
    oneVideographerHourElopements,
    setOneVideographerHourElopements,
    setOneVideographerControlElopements,
    oneVideographerSelectedElopements,
    setOneVideographerSelectedElopements,
  } = state;

  handlePreventDefault(ev);
  // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
  if (oneVideographerSelectedElopements === false) {
    setOneVideographerSelectedElopements(true);
  }

  if (control === 'decrease') {
    setOneVideographerControlElopements('decrease');
    if (oneVideographerHourElopements > 1) {
      setOneVideographerHourElopements(oneVideographerHourElopements - 1);
    }
  }
  if (control === 'increase') {
    setOneVideographerControlElopements('increase');
    if (oneVideographerHourElopements < 4) {
      setOneVideographerHourElopements(oneVideographerHourElopements + 1);
    }
  }
  // }
  // handleKeyPressed(ev.keyCode);
};
//#endregion
//#region Sneak Peek
// TODO: accessibility state
export const handleSocialMediaPackagePhotoSelectedElopements = (ev, state) => {
  const {
    socialMediaPackagePhotoSelectedElopements,
    setSocialMediaPackagePhotoSelectedElopements,
    setSocialMediaPackageVideoSelectedElopements,
    setSocialMediaPackagePhotoAndVideoSelectedElopements,
  } = state;

  // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
  setSocialMediaPackagePhotoSelectedElopements(
    !socialMediaPackagePhotoSelectedElopements
  );
  setSocialMediaPackageVideoSelectedElopements(false);
  setSocialMediaPackagePhotoAndVideoSelectedElopements(false);
  //   handleKeyPressed(ev.keyCode);
  // }
};
// TODO: accessibility state
export const handleSocialMediaPackageVideoSelectedElopements = (ev, state) => {
  const {
    socialMediaPackageVideoSelectedElopements,
    setSocialMediaPackageVideoSelectedElopements,
    setSocialMediaPackagePhotoSelectedElopements,
    setSocialMediaPackagePhotoAndVideoSelectedElopements,
  } = state;

  // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
  setSocialMediaPackageVideoSelectedElopements(
    !socialMediaPackageVideoSelectedElopements
  );
  setSocialMediaPackagePhotoSelectedElopements(false);
  setSocialMediaPackagePhotoAndVideoSelectedElopements(false);
  //   handleKeyPressed(ev.keyCode);
  // }
};

// TODO: accessibility state
export const handleSocialMediaPackagePhotoAndVideoSelectedElopements = (
  ev,
  state
) => {
  const {
    socialMediaPackagePhotoAndVideoSelectedElopements,
    setSocialMediaPackagePhotoAndVideoSelectedElopements,
    setSocialMediaPackagePhotoSelectedElopements,
    setSocialMediaPackageVideoSelectedElopements,
  } = state;

  // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
  setSocialMediaPackagePhotoAndVideoSelectedElopements(
    !socialMediaPackagePhotoAndVideoSelectedElopements
  );
  setSocialMediaPackagePhotoSelectedElopements(false);
  setSocialMediaPackageVideoSelectedElopements(false);
  //   handleKeyPressed(ev.keyCode);
  // }
};
//#endregion
//#region Officiant
// TODO: accessiblity state
export const handleOfficiantSelectedElopements = (ev, state) => {
  const { officiantSelectedElopements, setOfficiantSelectedElopements } = state;
  // if (ev.type === 'click' || (ev.keyCode === 13 && !keyPressed)) {
  setOfficiantSelectedElopements(!officiantSelectedElopements);
  // }
  // handleKeyPressed(ev.keyCode);
};
//#endregion
