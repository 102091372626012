export function updateUrlParam(uriString, selectedValue) {
  const params = new URLSearchParams(window.location.search);
  params.set(uriString, selectedValue);
  // window.history.replaceState(
  window.history.pushState(
    {},
    '',
    `${window.location.pathname}?${params.toString()}`
  );
}

export const getUrlParams = (param, staticURL, decode) => {
  let currentLocation =
    staticURL && staticURL.length ? staticURL : location.search;
  if (!currentLocation.includes('?')) {
    return false;
  }
  let paramArr = currentLocation.split('?')[1]?.split('&') || [];

  let returnBool = true;

  for (let i = 0; i < paramArr.length; i++) {
    let parr = paramArr[i]?.split('=') || [];
    if (parr[0] === param) {
      return decode ? decodeURIComponent(parr[1]) : parr[1];
    } else {
      returnBool = false;
    }
  }
  if (!returnBool) return false;
  return ''; // return an empty string if the parameter is not found
};

// export function getUrlParams(param, staticURL, decode) {
//   // let currentLocation = staticURL.length ? staticURL : window.location.search;
//   // let paramArr = currentLocation.split('?')[1].split('&');
//   // let returnBool = true;

//   // for (let i = 0; i < paramArr.length; i++) {
//   //   let parr = paramArr[i].split('=');
//   //   // console.log('PARAM UCP', parr);
//   //   if (parr[0] === param) {
//   //     return decode ? decodeURIComponent(parr[1]) : parr[1];
//   //   } else {
//   //     returnBool = false;
//   //   }
//   // }
//   // if (!returnBool) return false;

//   let currentLocation =
//     staticURL && staticURL.length ? staticURL : window.location.search;

//   if (!currentLocation.includes('?')) {
//     return false;
//   }

//   let paramArr = currentLocation && currentLocation.split('?')[1].split('&');
//   let paramArrLength = paramArr && paramArr.length;
//   let returnBool = true;

//   for (let i = 0; i < paramArrLength; i++) {
//     let parr = paramArr[i].split('=');
//     // let parr = paramArr[i]?.split('=') || [];
//     // console.log('PARAM CP', parr);
//     if (parr[0] === param) {
//       return decode ? decodeURIComponent(parr[1]) : parr[1];
//     } else {
//       returnBool = false;
//     }
//   }
//   if (!returnBool) return false;
//   // eslint-disable-next-line react-hooks/exhaustive-deps
// }

// export const getUrlParams = useCallback((param, staticURL, decode) => {
//   let currentLocation = staticURL.length ? staticURL : location.search;
//   let paramArr = currentLocation.split('?')[1].split('&');
//   let returnBool = true;

//   console.log('PARAM ARR', paramArr);
//   for (let i = 0; i < paramArr.length; i++) {
//     let parr = paramArr[i].split('=');
//     if (parr[0] === param) {
//       return decode ? decodeURIComponent(parr[1]) : parr[1];
//     } else {
//       returnBool = false;
//     }
//   }
//   if (!returnBool) return false;
//   // eslint-disable-next-line react-hooks/exhaustive-deps
// }, []);
