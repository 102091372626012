import { useState } from 'react';
import { getUrlParams } from '../utils/urlParamsUtils';

export const usePackageConfiguratorElopementsState = (graphqlData) => {
  //#region One Photographer
  const [
    onePhotographerSelectedElopements,
    setOnePhotographerSelectedElopements,
  ] = useState(() => {
    const p1a = getUrlParams('p1', location.search, true);
    if (p1a === 'true' || p1a === true) return true;
    return false;
  });
  const [
    onePhotographerControlElopements,
    setOnePhotographerControlElopements,
  ] = useState('');
  const [onePhotographerHourElopements, setOnePhotographerHourElopements] =
    useState(() => {
      const p1t = Number(getUrlParams('p1t', location.search, true));
      if (p1t < 1) return 1;
      if (p1t > 4) return 4;
      if (p1t) {
        return Number(getUrlParams('p1t', location.search, true));
      } else {
        return 1;
      }
    });
  const [onePhotographerPriceDefaultElopements] = useState(
    Number(graphqlData.onePhotographerElopements.priceFor1)
  );
  const [onePhotographerPriceElopements, setOnePhotographerPriceElopements] =
    useState(Number(graphqlData.onePhotographerElopements.priceFor1));
  const [onePhotographerPricePerHourElopements] = useState(
    Number(graphqlData.onePhotographerElopements.pricePerHour)
  );
  //#endregion
  //#region Wedding Albums
  const [uriChangeElopements, setUriChangeElopements] = useState(false);
  //#region 10x10
  const [weddingAlbum10PriceElopements, setWeddingAlbum10PriceElopements] =
    useState(0);
  const [
    weddingAlbum10PriceTotalElopements,
    setWeddingAlbum10PriceTotalElopements,
  ] = useState(0);
  const [
    weddingAlbum10SelectedElopements,
    setWeddingAlbum10SelectedElopements,
  ] = useState(() => {
    const w10 = getUrlParams('w10', location.search, true);
    if (w10 === 'true' || w10 === true) return true;
    return false;
  });
  const [weddingAlbum10AmountElopements, setWeddingAlbum10AmountElopements] =
    useState(() => {
      const w10a = Number(getUrlParams('w10a', location.search, true));
      if (w10a < 1) return 1;
      if (w10a) {
        return Number(getUrlParams('w10a', location.search, true));
      } else {
        return 1;
      }
    });
  const [
    weddingAlbum10AmountFreeElopements,
    setWeddingAlbum10AmountFreeElopements,
  ] = useState(() => {
    const w10af = Number(getUrlParams('w10af', location.search, true));
    if (w10af < 1) return 0;
    if (w10af) {
      return Number(getUrlParams('w10af', location.search, true));
    } else {
      return 0;
    }
  });
  const [
    weddingAlbum10ProfessionalDesignAmountElopements,
    setWeddingAlbum10ProfessionalDesignAmountElopements,
  ] = useState(() => {
    const w10pda = Number(getUrlParams('w10pda', location.search, true));
    if (w10pda) {
      if (w10pda < 1) return 1;
      return Number(getUrlParams('w10pda', location.search, true));
    } else {
      return 1;
    }
  });
  const [
    weddingAlbum10ProfessionalDesignSelectedElopements,
    setWeddingAlbum10ProfessionalDesignSelectedElopements,
  ] = useState(() => {
    const w10pds = getUrlParams('w10pds', location.search, true);
    if (w10pds === 'true' || w10pds === true) return true;
    return false;
  });
  const [weddingAlbum10ControlElopements, setWeddingAlbum10ControlElopements] =
    useState('');
  const [weddingAlbum10InfoElopements, setWeddingAlbum10InfoElopements] =
    useState('Album');
  const [
    weddingAlbum10AmountFreeInfoElopements,
    setWeddingAlbum10AmountFreeInfoElopements,
  ] = useState('Album');
  const [
    weddingAlbum10ProfessionalDesignInfoElopements,
    setWeddingAlbum10ProfessionalDesignInfoElopements,
  ] = useState('Design');
  const [weddingAlbum10PriceDefaultElopements] = useState(
    Number(graphqlData.weddingAlbum10Elopements.price)
  );
  const [weddingAlbum10ShippingFeeElopements] = useState(
    Number(graphqlData.weddingAlbum10Elopements.shippingFee)
  );
  const [
    weddingAlbum10ShippingFeeTotalElopements,
    setWeddingAlbum10ShippingFeeTotalElopements,
  ] = useState(Number(graphqlData.weddingAlbum10Elopements.shippingFee));
  const [weddingAlbum10ProfessionalDesignPriceElopements] = useState(
    Number(graphqlData.weddingAlbum10Elopements.professionalAlbumDesignPrice)
  );
  const [
    weddingAlbum10ProfessionalDesignPriceTotalElopements,
    setWeddingAlbum10ProfessionalDesignPriceTotalElopements,
  ] = useState(
    Number(graphqlData.weddingAlbum10Elopements.professionalAlbumDesignPrice)
  );
  //#endregion
  //#region 12x12
  const [weddingAlbum12PriceElopements, setWeddingAlbum12PriceElopements] =
    useState(0);
  const [
    weddingAlbum12PriceTotalElopements,
    setWeddingAlbum12PriceTotalElopements,
  ] = useState(0);
  const [
    weddingAlbum12SelectedElopements,
    setWeddingAlbum12SelectedElopements,
  ] = useState(() => {
    const w12 = getUrlParams('w12', location.search, true);
    if (w12 === 'true' || w12 === true) return true;
    return false;
  });
  const [weddingAlbum12AmountElopements, setWeddingAlbum12AmountElopements] =
    useState(() => {
      const w12a = Number(getUrlParams('w12a', location.search, true));
      if (w12a < 1) return 1;
      if (w12a) {
        return Number(getUrlParams('w12a', location.search, true));
      } else {
        return 1;
      }
    });
  const [
    weddingAlbum12AmountFreeElopements,
    setWeddingAlbum12AmountFreeElopements,
  ] = useState(() => {
    const w12af = Number(getUrlParams('w12af', location.search, true));
    if (w12af < 1) return 0;
    if (w12af) {
      return Number(getUrlParams('w12af', location.search, true));
    } else {
      return 0;
    }
  });
  const [
    weddingAlbum12ProfessionalDesignAmountElopements,
    setWeddingAlbum12ProfessionalDesignAmountElopements,
  ] = useState(() => {
    const w12pda = Number(getUrlParams('w12pda', location.search, true));
    if (w12pda) {
      if (w12pda < 1) return 1;
      return Number(getUrlParams('w12pda', location.search, true));
    } else {
      return 1;
    }
  });
  const [
    weddingAlbum12ProfessionalDesignSelectedElopements,
    setWeddingAlbum12ProfessionalDesignSelectedElopements,
  ] = useState(() => {
    const w12pds = getUrlParams('w12pds', location.search, true);
    if (w12pds === 'true' || w12pds === true) return true;
    return false;
  });
  const [weddingAlbum12ControlElopements, setWeddingAlbum12ControlElopements] =
    useState('');
  const [weddingAlbum12InfoElopements, setWeddingAlbum12InfoElopements] =
    useState('Album');
  const [
    weddingAlbum12AmountFreeInfoElopements,
    setWeddingAlbum12AmountFreeInfoElopements,
  ] = useState('Album');
  const [
    weddingAlbum12ProfessionalDesignInfoElopements,
    setWeddingAlbum12ProfessionalDesignInfoElopements,
  ] = useState('Design');
  const [weddingAlbum12PriceDefaultElopements] = useState(
    Number(graphqlData.weddingAlbum12Elopements.price)
  );
  const [weddingAlbum12ShippingFeeElopements] = useState(
    Number(graphqlData.weddingAlbum12Elopements.shippingFee)
  );
  const [
    weddingAlbum12ShippingFeeTotalElopements,
    setWeddingAlbum12ShippingFeeTotalElopements,
  ] = useState(Number(graphqlData.weddingAlbum12Elopements.shippingFee));
  const [weddingAlbum12ProfessionalDesignPriceElopements] = useState(
    Number(graphqlData.weddingAlbum12Elopements.professionalAlbumDesignPrice)
  );
  const [
    weddingAlbum12ProfessionalDesignPriceTotalElopements,
    setWeddingAlbum12ProfessionalDesignPriceTotalElopements,
  ] = useState(
    Number(graphqlData.weddingAlbum10Elopements.professionalAlbumDesignPrice)
  );
  //#endregion
  //#endregion
  //#region Premium package (Photo)
  const [
    premiumPhotoPackageSelectedElopements,
    setPremiumPhotoPackageSelectedElopements,
  ] = useState(() => {
    const pppa = getUrlParams('ppp', location.search, true);
    if (pppa === 'true' || pppa === true) return true;
    return false;
  });
  const [
    premiumPhotoPackagePriceElopements,
    setPremiumPhotoPackagePriceElopements,
  ] = useState(Number(graphqlData.premiumPhotoPackageElopements.price));
  const [premiumPhotoPackagePriceDefaultElopements] = useState(
    Number(graphqlData.premiumPhotoPackageElopements.price)
  );
  // TODO: if/when needed, combined with premiumPhotoPackagePriceDiscountElopements
  // const [premiumPhotoPackagePriceDiscountElopements] = useState(
  //   Number(graphqlData.premiumPhotoPackageElopements.discount)
  // );
  const [premiumPhotoPackageDescriptionElopements] = useState(
    graphqlData.premiumPhotoPackageElopements.description
  );
  const [
    premiumPhotoPackagePopupTitleElopements,
    setPremiumPhotoPackagePopupTitleElopements,
  ] = useState(graphqlData.premiumPhotoPackageElopements.popupTitle);
  // TODO: if/when needed
  // const [premiumPhotoPackagePopupTitleDefaultElopements] = useState(
  //   graphqlData.premiumPhotoPackageElopements.popupTitle
  // );
  // const [premiumPhotoPackagePopupTitleDiscountElopements] = useState(
  //   graphqlData.premiumPhotoPackageElopements.popupTitleDiscount
  // );
  const [premiumPhotoPackagePopupBodyElopements] = useState(
    graphqlData.premiumPhotoPackageElopements.popupBody
  );
  //#endregion
  //#region One Videographer
  const [
    oneVideographerSelectedElopements,
    setOneVideographerSelectedElopements,
  ] = useState(() => {
    const v1a = getUrlParams('v1', location.search, true);
    if (v1a === 'true' || v1a === true) return true;
    return false;
  });
  const [
    oneVideographerControlElopements,
    setOneVideographerControlElopements,
  ] = useState('');
  const [oneVideographerHourElopements, setOneVideographerHourElopements] =
    useState(() => {
      const v1t = Number(getUrlParams('v1t', location.search, true));
      if (v1t < 1) return 1;
      if (v1t > 4) return 4;
      if (v1t) {
        return Number(getUrlParams('v1t', location.search, true));
      } else {
        return 1;
      }
    });
  const [oneVideographerPriceDefaultElopements] = useState(
    Number(graphqlData.oneVideographerElopements.priceFor1)
  );
  const [oneVideographerPriceElopements, setOneVideographerPriceElopements] =
    useState(Number(graphqlData.oneVideographerElopements.priceFor1));

  const [oneVideographerPricePerHourElopements] = useState(
    Number(graphqlData.oneVideographerElopements.pricePerHour)
  );
  //#endregion

  // Social Media Package (Sneak Peek)
  //#region Sneak Peek Photo
  const [
    socialMediaPackagePhotoSelectedElopements,
    setSocialMediaPackagePhotoSelectedElopements,
  ] = useState(() => {
    const sppa = getUrlParams('spp', location.search, true);
    if (sppa === 'true' || sppa === true) return true;
    return false;
  });
  const [socialMediaPackagePhotoPriceElopements] = useState(
    Number(graphqlData.socialMediaPackageElopements.photo.price)
  );
  const [socialMediaPackagePhotoReelsElopements] = useState(
    graphqlData.socialMediaPackageElopements.photo
  );
  //#endregion
  //#region Sneak Peek Video
  const [
    socialMediaPackageVideoSelectedElopements,
    setSocialMediaPackageVideoSelectedElopements,
  ] = useState(() => {
    const spva = getUrlParams('spv', location.search, true);
    if (spva === 'true' || spva === true) return true;
    return false;
  });
  const [socialMediaPackageVideoPriceElopements] = useState(
    Number(graphqlData.socialMediaPackageElopements.video.price)
  );
  const [socialMediaPackageVideoReelsElopements] = useState(
    graphqlData.socialMediaPackageElopements.video
  );
  //#endregion
  //#region Sneak Peek Photo & Video
  const [
    socialMediaPackagePhotoAndVideoSelectedElopements,
    setSocialMediaPackagePhotoAndVideoSelectedElopements,
  ] = useState(() => {
    const sppva = getUrlParams('sppv', location.search, true);
    if (sppva === 'true' || sppva === true) return true;
    return false;
  });
  const [socialMediaPackagePhotoAndVideoPriceElopements] = useState(
    Number(graphqlData.socialMediaPackageElopements.photoAndVideo.price)
  );
  const [socialMediaPackagePhotoAndVideoReelsElopements] = useState(
    graphqlData.socialMediaPackageElopements.photoAndVideo
  );
  //#endregion
  //#region Premium Package (Video)
  const [
    premiumVideoPackageSelectedElopements,
    setPremiumVideoPackageSelectedElopements,
  ] = useState(() => {
    const pvp = getUrlParams('pvp', location.search, true);
    if (pvp === 'true' || pvp === true) return true;
    return false;
  });
  const [
    premiumVideoPackagePriceElopements,
    setPremiumVideoPackagePriceElopements,
  ] = useState(Number(graphqlData.premiumVideoPackageElopements.price));
  const [premiumVideoPackagePriceDefaultElopements] = useState(
    Number(graphqlData.premiumVideoPackageElopements.price)
  );
  const [premiumVideoPackagePriceDiscountElopements] = useState(
    Number(graphqlData.premiumVideoPackageElopements.discount)
  );
  const [premiumVideoPackageDescriptionElopements] = useState(
    graphqlData.premiumVideoPackageElopements.description
  );
  // const [premiumVideoPackageDescriptionDefaultElopements] = useState(
  //   premiumVideoPackageElopements.description
  // );
  // in elopmenets there is no extended trailer
  // so this is not needed
  // const [
  //   premiumVideoPackageDescriptionElopements,
  //   setPremiumVideoPackageDescriptionElopements,
  // ] = useState('');
  // const [premiumVideoPackageDescriptionDefaultElopements] = useState(
  //   premiumVideoPackageElopements.description
  // );
  const [
    premiumVideoPackagePopupTitleElopements,
    setPremiumVideoPackagePopupTitleElopements,
  ] = useState(graphqlData.premiumVideoPackageElopements.popupTitle);
  const [premiumVideoPackagePopupTitleDefaultElopements] = useState(
    graphqlData.premiumVideoPackageElopements.popupTitle
  );
  const [premiumVideoPackagePopupTitleDiscountElopements] = useState(
    graphqlData.premiumVideoPackageElopements.popupTitleDiscount
  );
  const [premiumVideoPackagePopupBodyElopements] = useState(
    graphqlData.premiumVideoPackageElopements.popupBody
  );
  //#endregion
  //#region Officiant
  const [officiantSelectedElopements, setOfficiantSelectedElopements] =
    useState(() => {
      const o1 = getUrlParams('o1', location.search, true);
      if (o1 === 'true' || o1 === true) return true;
      return false;
    });
  const [officiantPriceElopements, setOfficiantPriceElopements] = useState(
    Number(graphqlData.officiantElopements.price)
  );
  //#endregion

  return {
    // One Photographer
    onePhotographerSelectedElopements,
    setOnePhotographerSelectedElopements,
    onePhotographerControlElopements,
    setOnePhotographerControlElopements,
    onePhotographerHourElopements,
    setOnePhotographerHourElopements,
    onePhotographerPriceDefaultElopements,
    onePhotographerPriceElopements,
    setOnePhotographerPriceElopements,
    onePhotographerPricePerHourElopements,
    // Wedding albums
    uriChangeElopements,
    setUriChangeElopements,
    // 10x10
    weddingAlbum10PriceElopements,
    setWeddingAlbum10PriceElopements,
    weddingAlbum10PriceTotalElopements,
    setWeddingAlbum10PriceTotalElopements,
    weddingAlbum10SelectedElopements,
    setWeddingAlbum10SelectedElopements,
    weddingAlbum10AmountElopements,
    setWeddingAlbum10AmountElopements,
    weddingAlbum10AmountFreeElopements,
    setWeddingAlbum10AmountFreeElopements,
    weddingAlbum10ProfessionalDesignAmountElopements,
    setWeddingAlbum10ProfessionalDesignAmountElopements,
    weddingAlbum10ProfessionalDesignSelectedElopements,
    setWeddingAlbum10ProfessionalDesignSelectedElopements,
    weddingAlbum10ControlElopements,
    setWeddingAlbum10ControlElopements,
    weddingAlbum10InfoElopements,
    setWeddingAlbum10InfoElopements,
    weddingAlbum10AmountFreeInfoElopements,
    setWeddingAlbum10AmountFreeInfoElopements,
    weddingAlbum10ProfessionalDesignInfoElopements,
    setWeddingAlbum10ProfessionalDesignInfoElopements,
    weddingAlbum10PriceDefaultElopements,
    weddingAlbum10ShippingFeeElopements,
    weddingAlbum10ShippingFeeTotalElopements,
    setWeddingAlbum10ShippingFeeTotalElopements,
    weddingAlbum10ProfessionalDesignPriceElopements,
    weddingAlbum10ProfessionalDesignPriceTotalElopements,
    setWeddingAlbum10ProfessionalDesignPriceTotalElopements,
    // 12x12
    weddingAlbum12PriceElopements,
    setWeddingAlbum12PriceElopements,
    weddingAlbum12PriceTotalElopements,
    setWeddingAlbum12PriceTotalElopements,
    weddingAlbum12SelectedElopements,
    setWeddingAlbum12SelectedElopements,
    weddingAlbum12AmountElopements,
    setWeddingAlbum12AmountElopements,
    weddingAlbum12AmountFreeElopements,
    setWeddingAlbum12AmountFreeElopements,
    weddingAlbum12ProfessionalDesignAmountElopements,
    setWeddingAlbum12ProfessionalDesignAmountElopements,
    weddingAlbum12ProfessionalDesignSelectedElopements,
    setWeddingAlbum12ProfessionalDesignSelectedElopements,
    weddingAlbum12ControlElopements,
    setWeddingAlbum12ControlElopements,
    weddingAlbum12InfoElopements,
    setWeddingAlbum12InfoElopements,
    weddingAlbum12AmountFreeInfoElopements,
    setWeddingAlbum12AmountFreeInfoElopements,
    weddingAlbum12ProfessionalDesignInfoElopements,
    setWeddingAlbum12ProfessionalDesignInfoElopements,
    weddingAlbum12PriceDefaultElopements,
    weddingAlbum12ShippingFeeElopements,
    weddingAlbum12ShippingFeeTotalElopements,
    setWeddingAlbum12ShippingFeeTotalElopements,
    weddingAlbum12ProfessionalDesignPriceElopements,
    weddingAlbum12ProfessionalDesignPriceTotalElopements,
    setWeddingAlbum12ProfessionalDesignPriceTotalElopements,
    // Premium package (Photo)
    premiumPhotoPackageSelectedElopements,
    setPremiumPhotoPackageSelectedElopements,
    premiumPhotoPackagePriceElopements,
    setPremiumPhotoPackagePriceElopements,
    premiumPhotoPackagePriceDefaultElopements,
    // premiumPhotoPackagePriceDiscountElopements,
    premiumPhotoPackageDescriptionElopements,
    premiumPhotoPackagePopupTitleElopements,
    setPremiumPhotoPackagePopupTitleElopements,
    // premiumPhotoPackagePopupTitleDefaultElopements,
    // premiumPhotoPackagePopupTitleDiscountElopements,
    premiumPhotoPackagePopupBodyElopements,
    // One Videographer
    oneVideographerSelectedElopements,
    setOneVideographerSelectedElopements,
    oneVideographerControlElopements,
    setOneVideographerControlElopements,
    oneVideographerHourElopements,
    setOneVideographerHourElopements,
    oneVideographerPriceDefaultElopements,
    oneVideographerPriceElopements,
    setOneVideographerPriceElopements,
    oneVideographerPricePerHourElopements,
    // Premium package (Video)
    premiumVideoPackageSelectedElopements,
    setPremiumVideoPackageSelectedElopements,
    premiumVideoPackagePriceElopements,
    setPremiumVideoPackagePriceElopements,
    premiumVideoPackagePriceDefaultElopements,
    premiumVideoPackagePriceDiscountElopements,
    premiumVideoPackageDescriptionElopements,
    premiumVideoPackagePopupTitleElopements,
    setPremiumVideoPackagePopupTitleElopements,
    premiumVideoPackagePopupTitleDefaultElopements,
    premiumVideoPackagePopupTitleDiscountElopements,
    premiumVideoPackagePopupBodyElopements,
    // Social media package (Sneak Peek)
    // Photo
    socialMediaPackagePhotoSelectedElopements,
    setSocialMediaPackagePhotoSelectedElopements,
    socialMediaPackagePhotoPriceElopements,
    socialMediaPackagePhotoReelsElopements,
    // Video
    socialMediaPackageVideoSelectedElopements,
    setSocialMediaPackageVideoSelectedElopements,
    socialMediaPackageVideoPriceElopements,
    socialMediaPackageVideoReelsElopements,
    // Photo & Video
    socialMediaPackagePhotoAndVideoSelectedElopements,
    setSocialMediaPackagePhotoAndVideoSelectedElopements,
    socialMediaPackagePhotoAndVideoPriceElopements,
    socialMediaPackagePhotoAndVideoReelsElopements,
    // Officiant
    officiantSelectedElopements,
    setOfficiantSelectedElopements,
    officiantPriceElopements,
    setOfficiantPriceElopements,
  };
};
